import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './TestQuestions.css';
import Cookies from 'js-cookie';
import PageLog from '../../PageLog/PageLog';
import DisplayMedia from './DisplayMedia';

function TestQuestions() {
  const { id } = useParams();
  const role_id = Cookies.get("role_id");
  const firmid = Cookies.get("firmid");
  const [questions, setQuestions] = useState([]);
  
  console.log("role_id", role_id);
  console.log("id", id);

  useEffect(() => {
    axios.post("/api/getquestions", { id, firmid, role_id })
      .then((response) => {
        setQuestions(response.data);
        console.log("response.data", response.data);
        console.log("id", id);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [id, firmid, role_id]);

  return (
    <div>
      <PageLog />
      <center>
        <h1>QM Questions</h1>
      </center>
      <div className="test-questions-container">
        <div className="test-questions-form">
          <ol>
            {questions.map((topic) => (
              <li key={topic.Q_ID}>
                <a href={`/answerbox/${topic.QM_ID}/${topic.Q_ID}`}>
                  {topic.QUESTION}
                </a>
              </li>
            ))}
          </ol>
        </div>
        <div className="test-questions-media">
          <DisplayMedia id={id} />
        </div>
      </div>
    </div>
  );
}

export default TestQuestions;
