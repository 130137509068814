import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';



// Main App component that fetches and displays competitors
const CompetitorList = () => {
    const [competitors, setCompetitors] = useState([]);
    const companyId = Cookies.get("firmid"); 

    console.log("firmid",companyId)
    
  
    // Fetch competitors data from the backend
    const fetchCompetitors = () => {
      axios
        .get(`/api/competitors/${companyId}`)
        .then((response) => {
          setCompetitors(response.data);
        })
        .catch((error) => {
          console.error('Error fetching competitors:', error);
        });
    };
  
    useEffect(() => {
      fetchCompetitors();
    }, []);
  
    return (
      <div className="container">
        {/* Display Competitors List */}
      
        <h2>Competitors List</h2>
        {competitors.length > 0 ? (
          <table>
            <thead>
              <tr>
                <th>Name</th> {/* Removed "Created At" */}
              </tr>
            </thead>
            <tbody>
              {competitors.map((competitor) => (
                <tr key={competitor.ID}>
                  <td>{competitor.NAME}</td> {/* Only displaying Name */}
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p className="no-competitors">No competitors found for the given company ID.</p>
        )}
  
        {/* Add Competitor Form */}
        {/* <AddCompetitorForm onCompetitorAdded={fetchCompetitors} /> */}
      </div>
    );
  };
  
  export default CompetitorList;
  