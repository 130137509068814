import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import './Upload.scss';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import { PostAdd } from '@mui/icons-material';
import logo from '../../public/upload-image.png'
// import PageLog from '../../PageLog/PageLog';
// import FacebookLogin from './FacebookLogin';
// import InstaLogin from './Instagram_Posting/InstaLogin';
import { useNavigate, useLocation } from 'react-router-dom';
import gifshot from 'gifshot';


const Upload = () => {

  const fileInputRef = useRef(null);

  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedOptionfinal, setSelectedOptionFinal] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [story, setStory] = useState('');
  const [storyLength, setStoryLength] = useState('');
  const [destination, setDestination] = useState('None');
  const [data, setData] = useState([]);
  const userid = Cookies.get("userid")
  const portalid = Cookies.get("portalid")
  const bardkey = Cookies.get("bardkey")
  const facebook_status = Cookies.get("facebook_status")
  const instagram_status = Cookies.get("instagram_status")
  const [currentPage, setCurrentPage] = useState(1); // Current page number
  const [totalPages, setTotalPages] = useState(1); // Total pages
  const rowsPerPage = 5; // Rows per page
  const [imagesList, setImagesList] = useState([]);
  const [imageDetails, setImageDetails] = useState([]);
  const content_category_id = Cookies.get("content_category_id")
  const [loading, setLoading] = useState(false);

  const [genstory, setGeneratedStory] = useState('');
  const homepath = 'images/MyB_App';
  const finalpath = `${homepath}/${portalid}/${userid}`;
  const navigate = useNavigate()
  const maxFiles = 5;


  // const finalpath = `${portalid}/${userid}`;

  // if (document.querySelector(".image-select")) {
  //   const dropArea = document.querySelector(".image-select"),
  //     button = dropArea.querySelector("button"),
  //     input = dropArea.querySelector("input");

  //   button.onclick = () => {
  //     input.click();
  //   };
  // }


  useEffect(() => {
    if (!userid) {
      alert("Please login");
      navigate('/login');
    }
  }, [userid, navigate]);

  useEffect(() => {
    fetchContent();
    fetchImageData(1);
    createfolder();

  }, []);

  const facebook_token = Cookies.get("facebook_token")
  const createfolder = async () => {

    try {
      const response = axios.post('/create/folder/session', { "portalid": portalid, "userid": userid });
    }


    catch (error) {
      console.log(error);
    }

  };

  const fetchContent = async (id) => {
    try {
      const response = await axios.get('/get/content/fromid', {
        params: { content_category_id }
      });
      if (response.data.length > 0) {
        setStory(response.data[0].BARD_KEYWORD); // Accessing the BARD_KEYWORD field of the first object
      } else {
        console.log('No data found');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const removeUpload = (index) => {
    if (selectedImage) {
      setSelectedImage(null);
      setImageDetails(null);
    }
    else {
      setImagesList((prevImagesList) =>
        prevImagesList.filter((_, i) => i !== index)
      );
    }
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  }

  const handleSingleImageUpload = (e) => {
    setSelectedImage(e.target.files[0]);
    const imageUpload = e.target.files[0];
    const imageUrl = URL.createObjectURL(imageUpload);
    const imageValues = {
      imageUrl: imageUrl,
      fileName: imageUpload.name
    }
    setImageDetails(imageValues);
  }

  const handleMultipleImagesUpload = (e) => {
    const inputImages = Array.from(e.target.files);
    inputImages.map(image => {
      const imageUrl = URL.createObjectURL(image);
      const imageValues = {
        imageUrl: imageUrl,
        selectedImage: image,
        fileName: image.name
      }
      setImagesList((prevImagesList) => [...prevImagesList, imageValues]);
    })
  }
  const handleImageChange = (e) => {
    const fileError = document.getElementById('fileError');
    if (e.target.files.length > 0) {
      if(e.target.files.length >5) {
        fileError.textContent = `You can upload up to ${maxFiles} files only.`;
        e.target.value = '';
      }
      else {
        fileError.textContent = ''; 
        e.target.files.length === 1 ? handleSingleImageUpload(e) : handleMultipleImagesUpload(e);
      }
    }
  };

  const handleOptionChange = (e) => {
    const selectedValue = e.target.value;
    if (selectedValue) {

      const [value, bard] = selectedValue.split('|');

      setSelectedOption(selectedValue);
      setSelectedOptionFinal(value);
      setStory(bard);
    }


  };

  const handleStoryChange = (e) => {
    setStory(e.target.value);
  };



  const handleStoryChangeGen = (e) => {
    setGeneratedStory(e.target.value);
  };

  const handleStoryLengthChange = (e) => {
    const selectedStoryLength = e.target.value;
    setStoryLength(selectedStoryLength);

    let appendText = '';
    if (selectedStoryLength === 'Paragraph') {
      appendText = 'write one paragraph on';
    } else if (selectedStoryLength === 'One page') {
      appendText = 'write one page on';
    } else if (selectedStoryLength === 'Two pages') {
      appendText = 'write two pages on';
    }

    // Append the selected text to the existing story in the textarea
    setStory((prevStory) => appendText + ' ' + prevStory);
  };

  const handleDestinationChange = (e) => {
    setDestination(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedImage) {
      alert("Please select an image");
      return;
    }


    const formData = new FormData();

    formData.append('category', selectedOptionfinal);

    if (genstory != '') {
      alert("your generated story will be uploaded")

      formData.append('story', genstory);

    }
    else {
      formData.append('story', story);
    }

    formData.append('storyLength', storyLength);
    formData.append('destination', destination);
    formData.append('finalpath', finalpath);
    formData.append('userid', userid);
    formData.append('portalid', portalid);
    // formData.append('selectedOption', selectedOption)
    formData.append('bardkey', bardkey)
    formData.append('image', selectedImage);

    console.log("formData", formData)

    try {
      if (destination === 'Bard') {
        await axios.post('/upload/bard', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      }
      //debugger
      if (destination === 'None') {
        try {
          const response = await axios.post('/upload/none', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
          alert(response.data)

          console.log("response:", response);
        } catch (error) {
          console.log("Error submitting:", error);
        }
      }

      // Clear form fields
      setSelectedOptionFinal('')
      setSelectedOption('');
      setSelectedImage(null);
      setStory('');
      setStoryLength('');
      setDestination('None');
      setGeneratedStory('')
      setSelectedImage(null);
      setImagesList([]);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }

      // Fetch updated data
      fetchImageData(1);
    } catch (error) {
      console.log(error);
    }
  };

  const approvestorycookieset = async (id) => {
    Cookies.set('approvestoryid', id);

  }



  const fetchImageData = async (page) => {

    console.log("page", page)
    try {
      const response = await axios.get('/imagedata', {
        params: {
          userid: userid,
          page: page // Send the specified page number
        },
      });
      setData(response.data.data);

      console.log("response.data.data", response.data.data)
      console.log("totalRows", response.data.totalRows)

      setCurrentPage(page); // Update current page state
      setTotalPages(Math.ceil(response.data.totalRows / rowsPerPage)); // Calculate total pages
    } catch (error) {
      console.log(error);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      const nextPage = currentPage + 1;
      fetchImageData(nextPage);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      const prevPage = currentPage - 1;
      fetchImageData(prevPage);
    }
  };



  const handleRewrite = async (model) => {

    setLoading(true);

    setGeneratedStory('')

    console.log("story", story)
    try {
      const response = await axios.post('/generate-summary', { story, model }, {
        responseType: 'text'
      });

      console.log('Received summary:');
      console.log(response.data); // Process the summary text as needed
      setGeneratedStory(response.data)
      setLoading(false);
    } catch (error) {
      console.error('Error fetching summary:', error.message);
      setLoading(false);
    }
  }

  const generateGif = () => {
    const images = imagesList.map(image => (image.imageUrl))
    gifshot.createGIF(
      {
        images: images,
        gifWidth: 300,
        gifHeight: 300,
        interval: 3,
        numFrames: images.length,
        frameDuration: 1,
      },
      (obj) => {
        setLoading(false);
        if (!obj.error) {
          saveGif(obj.image);
          setImagesList([]);
        } else {
          console.error('Error generating GIF:', obj.error);
        }
      }
    );

  }

  const saveGif = (gifEncoded) => {
    const base64Data = gifEncoded.replace(/^data:image\/gif;base64,/, '');
    const binaryString = window.atob(base64Data);
    const arrayBuffer = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
      arrayBuffer[i] = binaryString.charCodeAt(i);
    }
    const file = new File([arrayBuffer], 'generated.gif', { type: 'image/gif' });
    const imageUrl = URL.createObjectURL(file);
    setSelectedImage(file);
    const imageValues = {
      imageUrl: imageUrl,
      fileName: 'video.gif'
    }
    setImageDetails(imageValues);
  };




  return (
    <div className='content-container-upload'>
      {/* <PageLog /> */}

      {/* {['0', '1'].includes(facebook_status) && <FacebookLogin />}       */}
      <form onSubmit={handleSubmit}>
        <div>

          <div className="container">
            <div className="card card--accent">
              <h3>Upload New Post to Social Media</h3>
              <div className="drop_box">
                {imageDetails?.imageUrl !== undefined || imagesList.length > 0 ? (
                  imagesList.length > 0 ? (
                    <div className='upload-image-container'>
                      <div className='image-tab'>
                        {imagesList.map((image, index) => (
                          <div key={index} className="image-item">
                            <button type="button" onClick={() => removeUpload(index)} className="button-delete"> X </button>
                            <img className="file-upload-image" src={image.imageUrl} alt={`Uploaded ${image.fileName}`} />
                            <div>{image.fileName}</div>
                            <div className="image-title-wrap">
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className='story-buttons-container'>
                        <button type="button" onClick={generateGif} disabled={imagesList.length === 1} className="button-blue">Create Video</button>
                        <input type="file" hidden accept=".jpg, .jpeg, .png, .tiff, .tif" className='' id="image" ref={fileInputRef} onChange={handleMultipleImagesUpload} multiple />
                        {imagesList.length < 5 && <button className="button-blue" type='button' onClick={() => fileInputRef.current.click()}>Add Images</button>}
                      </div>
                        {imagesList.length === 1 && <div className='error-text'>Please add more images to create video</div>}
                    </div>) : (
                    <div className='image-tab'>
                      <img className="file-upload-image" src={imageDetails?.imageUrl} alt="your image" />
                      <div>{imageDetails?.fileName}</div>
                      <div className="image-title-wrap">
                        <button type="button" onClick={removeUpload} className="button-blue">Remove Image</button>
                      </div>
                    </div>)
                )
                  :
                  <div className='image-select'>
                    <img className='upload-icon' src={logo}></img>
                    <div className='upload-content'>
                      <header>
                        <h4>Select a Single Image</h4>
                        <div>or</div>
                        <h4>Select Upto 5 images to create a video</h4>
                      </header>
                      <p>Files Supported: Jpeg, Jpg, Png, Tiff, Tif</p>
                      {/* <input type="file" hidden accept=".jpg, .jpeg, .png, .tiff, .tif" className='' id="image" onChange={handleImageChange} multiple /> */}
                      <input type="file" hidden accept=".jpg, .jpeg, .png, .tiff, .tif" className='' id="image" ref={fileInputRef} onChange={handleImageChange} multiple />
                      {/* <button className="button-blue" type='button'>Choose Image</button> */}
                      <button className="button-blue" type='button' onClick={() => fileInputRef.current.click()}>Choose Image</button>
                      <div id="fileError" className='error-text'></div>
                      {selectedImage && selectedImage.name}
                    </div>
                  </div>
                }
              </div>
              <textarea id="story" className='caption-text' value={story} onChange={handleStoryChange} />
              <div className='disclaimer-text'>Info: Default text will be loaded in above box based on the category selected by you during signup</div>

              <div className="story-buttons-container">
                <button type="button" className="button-blue" onClick={() => handleRewrite('tinyllama')}>Quick rewrite</button>
                <button type="button" className="button-blue" onClick={() => handleRewrite('mistral-openorca:7b-q2_K')}>Quality rewrite</button>
              </div>
              <br />
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {loading && <img src="../images/website_images/Spinner-1s-200px.gif" alt="Loading..." style={{ width: '100px', height: '100px' }} />}
              </div>
              {genstory && <textarea id="story" className='caption-text' value={genstory} onChange={handleStoryChangeGen} />}
              <center> <a href='/imageuploadingwithlogoortext'>Upload Image Using logo/text</a></center>
              <div className="submit-button-container">
                <button type="submit" className='button-blue'>Submit</button>
              </div>
            </div>
          </div>
        </div>
      </form>

      <br />

      <br />



      <div className='previous-post-table'>
        <h2>Choose From Previous Posts</h2>
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Caption</th>
              <th>Date</th>
              <th>Modified </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data.map((item) => (
              <tr key={item.id}>
                <td>{item.id}</td>
                <td>{item.BARD_STORY}</td>
                <td>
                  {item.DATE ? new Date(item.DATE).toLocaleString() : ' '}
                </td>
                <td>{item.APPROVED === 'Y' ? 'Yes' : 'No'}</td>
                {/* <td><Link to={`/approvestory?id=${item.id}`} onClick={() => approvestorycookieset(item.id)}>Post</Link></td> */}
                <td><a href={`/approvestory?id=${item.id}`} onClick={() => approvestorycookieset(item.id)} className='button-blue'>Post</a></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="pagination">
        <div className="pagination-upload">
          <button onClick={handlePrevPage} disabled={currentPage === 1} className='button-blue'>Previous</button>
          <span>{currentPage} of {totalPages}</span>
          <button onClick={handleNextPage} className='button-blue' disabled={currentPage >= totalPages}>Next</button>
        </div>
      </div>
    </div>
  );
};

export default Upload;