import React, { useState, useEffect } from "react";
import axios from "axios";
import './SwotAnalysis.css';
import Cookies from 'js-cookie';
import { useNavigate, useLocation } from 'react-router-dom';
import PageLog from '../../PageLog/PageLog';
const SWOTReport = () => {
  const [swotAnalysisText, setSwotAnalysisText] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const firmid = Cookies.get("firmid"); 
  const userid = Cookies.get("userid");
    const navigate = useNavigate()
  
    useEffect(() => {
      if (!userid) {
        alert("Please login");
        navigate('/login');
      } 
    }, [userid, navigate]);
  

  useEffect(() => {
    const fetchSWOTAnalysis = async () => {
      try {
        const response = await axios.get(`/combined-info?companyId=${firmid}`);
        setSwotAnalysisText(response.data.swotAnalysis);
        setCompanyName(response.data.companyName);
      } catch (err) {
        console.error("Error fetching SWOT analysis:", err);
        setError("Failed to fetch SWOT analysis");
      } finally {
        setLoading(false);
      }
    };

    fetchSWOTAnalysis();
  }, [firmid]);

  return (
    <div className="swot-container">
         <PageLog />
      <h1 className="swot-title">SWOT Analysis {companyName && `- ${companyName}`}</h1>

      {loading ? (
        <div className="spinner"></div>
      ) : error ? (
        <p className="error">{error}</p>
      ) : (
        <div className="swot-section">
          <div
            className="swot-text"
            dangerouslySetInnerHTML={{ __html: swotAnalysisText.replace(/\n/g, "<br>") }}
          />
        </div>
      )}
    </div>
  );
};

export default SWOTReport;
