import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './testqm.css';
import PageLog from '../../PageLog/PageLog';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';



function TestQuestionsMain() {
  const { mainHead } = useParams();
  const role_id = Cookies.get("role_id");
  const firmid = Cookies.get("firmid");
  const [questions, setQuestions] = useState([]);

  console.log("Initial state:", { role_id, firmid, mainHead }); // Log initial state

  const userid = Cookies.get("userid");
  console.log("userid", userid)
  const navigate = useNavigate();

  useEffect(() => {
      if (!userid) {
          alert("Please login");
          navigate('/login');
      } 
  }, [userid, navigate]);

  useEffect(() => {
    console.log("Making API call to fetch questions with payload:", {
      firmid,
      role_id,
      mainHead
    });

    axios.post("/api/getquestions/main", { "firmid": firmid, "role_id": role_id, "mainHead": mainHead })
      .then((response) => {
        console.log("API response received:", response.data); // Log the full response

        setQuestions(response.data); // Use response.data and filter if necessary
      })
      .catch((error) => {
        console.error('Error fetching data:', error); // Log error details
      });
  }, [mainHead, firmid, role_id]);

  return (
    <div>
      <PageLog />
      <center>
        <h1>QM Questions</h1>
      </center>

      <div className='testqm-form-container'>
        <ol>
          {questions.map((topic) => (
            <li key={topic.QM_ID}>
              <a href={`/qmquestion/${topic.QM_ID}`}>{topic.TOPIC}</a> {/* Use QM_ID here */}
            </li>
          ))}
        </ol>
      </div>
 
    </div>
  );
}

export default TestQuestionsMain;
