import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './Details1.css';
import PageLog from '../../PageLog/PageLog';

const Details = () => {
    const [details, setDetails] = useState({});
    const { companyId } = useParams();
    const [isLoading, setIsLoading] = useState(false); // Loading state
    let site = sessionStorage.getItem('site');



    useEffect(() => {
        const site = sessionStorage.getItem('site');

        const fetchData = async () => {
            setIsLoading(true); // Start loading
            try {
                let response;
                if (site === 'Myblock') {
                    response = await axios.get(`/myblocks/details?companyId=${companyId}`);
                } else if (site === 'Techie-Index') {
                    response = await axios.get(`/tx/details?companyId=${companyId}`);
                } else {
                    throw new Error('Invalid site value');
                }

                setDetails(response.data);
                console.log('response.data', response.data);
            } catch (error) {
                console.error('Error fetching details:', error);
            } finally {
                setIsLoading(false); // Stop loading
            }
        };

        fetchData();
    }, [companyId]);


    const addHttp = (url) => {
        if (!url.match(/^[a-zA-Z]+:\/\//)) {
            return 'http://' + url;
        }
        return url;
    };

    return (
        isLoading ? (
            <div className="spinner" >
                <div className="spinner-circle"></div>
            </div >
        ) : (
            <div className="details-container">
                <PageLog />



                <div className="details-box">
                    {details.logo && (
                        <img src={details.logo} alt="Company Logo" className="details-logo" />
                    )}
                    <h1 className="details-heading">{details.company_name || 'N/A'}</h1>
                    <div className="details-section">
                        <p className="details-label">About:</p>
                        <p className="details-text">{details.About || 'N/A'}</p>
                    </div>
                    <div className="details-section">
                        <p className="details-label">Location:</p>
                        <p className="details-text">
                            {details.city && details.state && details.country
                                ? `${details.city}, ${details.state}, ${details.country}`
                                : 'N/A'}
                        </p>
                    </div>
                    <div className="details-section">
                        <p className="details-label">URL:</p>
                        <p className="details-text">
                            {details.url ? (
                                <a
                                    href={addHttp(details.url)}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {details.url}
                                </a>
                            ) : (
                                'N/A'
                            )}
                        </p>
                    </div>
                    <div className="details-section">
                        <p className="details-label">Founded Year:</p>
                        <p className="details-text">{details.founded || 'N/A'}</p>
                    </div>
                    <div className="details-section">
                        <p className="details-label">Total Funding:</p>
                        <p className="details-text">{details.total_funding || 'N/A'}</p>
                    </div>
                    <div className="details-section">
                        <p className="details-label">Employees:</p>
                        <p className="details-text">{details.employees || 'N/A'}</p>
                    </div>
                    {site === 'Techie-Index' && (
                        <div className="details-section">
                            <p className="details-label">LinkedIn:</p>
                            <p className="details-text">
                                {details.linkedin_url ? (
                                    <a
                                        href={details.linkedin_url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        LinkedIn Profile
                                    </a>
                                ) : (
                                    'N/A'
                                )}
                            </p>
                        </div>
                    )}

                    {site === 'Myblock' && (<>
                        <div className="details-section">
                            <p className="details-label">Facebook Followers:</p>
                            <p className="details-text">{details.FB_FOLLOWER_COUNT}</p>
                        </div>
                        <div className="details-section">
                            <p className="details-label">Instagram Followers:</p>
                            <p className="details-text">{details.INSTA_FOLLOWER_COUNT || 'N/A'}</p>
                        </div>
                        <div className="details-section">
                            <p className="details-label">LinkedIn Followers:</p>
                            <p className="details-text">{details.LINKEDIN_FOLLOWER_COUNT || 'N/A'}</p>
                        </div>
                    </>
                    )}

                </div>
            </div>)

    );
};

export default Details;
