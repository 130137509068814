import React, { useState, useEffect } from "react";
import axios from 'axios';
import './VendorCommentsDisplay.css';
import { useNavigate } from 'react-router-dom';
import PageLogLocation from '../../PageLog/PageLogLocation';
import Cookies from 'js-cookie';

function VendorCommentsDisplay() {
  const [vendId, setVendId] = useState('');
  const [vendTitl, setVendTitl] = useState('');
  const [results, setResults] = useState([]);
  const navigate = useNavigate();



  const userid = Cookies.get("userid");
  console.log("userid", userid);

  useEffect(() => {
    if (!userid) {
      alert("Please login");
      navigate('/login');
    }
  }, [userid, navigate]);

  const handleSearch = async () => {
    const query = {};
    if (vendId) query.VEND_ID = vendId;
    if (vendTitl) query.VEND_TITL = vendTitl;

    try {
      const response = await axios.get('/api/vendorcomments', { params: query });
      setResults(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleFetchVendorId = async () => {
    try {
      const response = await axios.post('/api/vendorcomments/getVENDID', {
        VEND_TITL: vendTitl // Send VEND_TITL to the backend to get VEND_ID
      });

      const vendId = response.data.VEND_ID;
      console.log("Fetched Vendor ID:", vendId);

      setVendId(vendId); // Optionally set this VEND_ID in the state for further use
    } catch (error) {
      console.error("Error fetching Vendor ID:", error.response?.data?.error || error.message);
    }
  };

  const handleAddCommentsClick = () => {
    navigate(`/vendorcommentsadding?id=${vendId}`);
  };

  const isSearchButtonEnabled = vendId || vendTitl; // Enable search when either vendId or vendTitl is present
  const isAddCommentsButtonEnabled = !!vendId; // Enable add comments button only when vendId is present

  return (
    <div className="add-data-form-container">
      <PageLogLocation />
      <h1>Vendor Comments Search</h1>
      <div className="form-group">
        <input
          type="text"
          placeholder="VEND_ID"
          value={vendId}
          onChange={(e) => setVendId(e.target.value)}
          className="input-field"
        />
        <input
          type="text"
          placeholder="VEND_TITL"
          value={vendTitl}
          onChange={(e) => setVendTitl(e.target.value)}
          className="input-field"
        />
        <button
          onClick={handleSearch}
          className="search-button"
          disabled={!isSearchButtonEnabled}
        >
          Search
        </button>
      </div>
      <br />
      <button
        onClick={handleAddCommentsClick}
        className="add-comments-button"
        disabled={!isAddCommentsButtonEnabled}
      >
        Add Comments
      </button>
      <ul className="results-list">
        {results.map((item) => (
          <li key={item.ID} className="result-item">
            <div className="vendor-info">
              <strong>Vendor Title:</strong> {item.VEND_TITL}
            </div>
            <div className="vendor-info">
              <strong>Vendor Name:</strong> {item.VENDOR_NAME}
            </div>
            <div className="vendor-info">
              <strong>Communication Method:</strong> {item.COMMUNICATION_METHOD}
            </div>
            <div className="vendor-info">
              <strong>Contact Person:</strong> {item.CONTACT_PERSON}
            </div>
            <div className="vendor-info">
              <strong>Department:</strong> {item.DEPARTMENT}
            </div>
            <div className="vendor-info">
              <strong>Vendor Start Date:</strong> {item.VEND_SDATE ? new Date(item.VEND_SDATE).toLocaleDateString() : 'Not provided'}
            </div>
            <div className="vendor-info">
              <strong>Next Date:</strong> {item.NEXT_DATE ? new Date(item.NEXT_DATE).toLocaleDateString() : 'Not provided'}
            </div>
            <div className="vendor-info">
              <strong>Vendor ID:</strong> {item.VEND_ID}
            </div>
            <div className="vendor-info">
              <strong>Date:</strong> {item.PDATE ? new Date(item.PDATE).toLocaleDateString() : 'Not provided'}
            </div>
            <div className="vendor-info">
              <strong>Phone:</strong> {item.PHONE}
            </div>
            <div className="vendor-info">
              <strong>Email:</strong> {item.EMAIL}
            </div>
            <div className="vendor-info">
              <strong>Contact Level:</strong> {item.CONTACT_LEVEL}
            </div>
            <div className="vendor-info">
              <strong>Comments:</strong> {item.COMMENTS}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default VendorCommentsDisplay;
