import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Styles.css';
import Cookies from 'js-cookie';
import Table from './Table';
import SubmissionResult from './SubmissionResult';

const Labform = ({ selectedTest, onSelectTest, testid }) => {

    console.log("onSelectTest", selectedTest)
    const userid = Cookies.get('userid');
    const portalid = Cookies.get('portalid');
    const username = Cookies.get('name');
    const [parameters, setParameters] = useState([]);
    const [headerTitle, setHeaderTitle] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [resultHistory, setResultHistory] = useState([]);
    const [values, setValues] = useState({});
    const [submissionResult, setSubmissionResult] = useState('');
    const [procedureCategory, setProcedureCategory] = useState('');
    const [isLoading, setIsLoading] = useState(false);



    useEffect(() => {
        setSubmissionResult(''); // Clear the result when test changes
    }, [selectedTest]);

    useEffect(() => {
        axios.get(`/api/parameters/${selectedTest}/${testid}`)
            .then(response => {
                if (response.data && response.data.length > 0) {
                    setProcedureCategory(response.data[0].procedure_category);
                    setParameters(response.data);

                    console.log("response.data", response.data)

                    const initialValues = response.data.reduce((acc, param) => {
                        acc[param.name] = '';
                        return acc;
                    }, {});
                    console.log("initialValues.data", initialValues)

                    setValues(initialValues);
                }
            })
            .catch(error => {
                setErrorMessage('Error fetching parameters: ' + error.message);
            });

        axios.get(`/api/header/${selectedTest}`)
            .then(response => {
                if (response.data && response.data.length > 0) {
                    setHeaderTitle(response.data[0].NAME);
                } else {
                    setHeaderTitle('Header Not Found');
                }
            })
            .catch(error => {
                setErrorMessage('Error fetching header: ' + error.message);
            });

        axios.get(`/api/result-history/${userid}`)
            .then(response => {
                setResultHistory(response.data);
            })
            .catch(error => {
                console.error('Error fetching result history:', error);
            });
    }, [selectedTest, userid]);

    const handleInputChange = (e, paramName) => {
        const updatedValues = { ...values };

        if (paramName === 'gender') {
            // If gender changes to male, clear the pregnancies field
            if (e.target.value === 'male') {
                updatedValues['Pregnancies'] = '';
            }
        }

        updatedValues[paramName] = e.target.value;
        setValues(updatedValues);
    };


    const handleSubmit = () => {
        setIsLoading(true);
        setSubmissionResult('Submitting...');
        setErrorMessage('');

        // Prepare values array
        // const updatedValues = {
        //     ...values,
        //     gender: values.gender === 'male' ? '0' : '1', // Convert gender to 0 (male) or 1 (female)
        // };

        const valuesArray = Object.values(values).map((val) => (val === '' ? null : val)); // Ensure empty fields are null

        axios
            .post('/api/submit', { values: valuesArray, portalid, userid, username, selectedTest, testid })
            .then((response) => {
                try {
                    if (response.status === 200) {
                        setSubmissionResult(response.data); // Handle the response result
                    }
                } catch (error) {
                    console.error('Error parsing response:', error);
                    setErrorMessage('Submission failed');
                }
            })
            .catch((error) => {
                console.error('Error submitting values:', error.response?.data?.error || error.message);
                setErrorMessage(error.response?.data?.error || 'Submission failed');
            })
            .finally(() => {
                setIsLoading(false);
            });
    };



    return (
        <div className="labform-and-history-container">
            <div className="labform-container">
                <p>Selected: {selectedTest} Disease</p>
                <Table parameters={parameters} values={values} handleInputChange={handleInputChange} procedureCategory={procedureCategory} />

                <button onClick={handleSubmit} disabled={isLoading}>
                    {isLoading ? 'Submitting...' : 'Submit'}
                </button>

                {/* {isLoading && (
                    <div className="loader">
                        <img src="../images/website_images/Spinner-1s-200px.gif" alt="Loading..." style={{ width: '100px', height: '100px' }} />
                    </div>
                )} */}
                {/* {submissionResult && (
                    <div className="submission-result">
                        <SubmissionResult submissionResult={submissionResult} />
                    </div>
                )} */}

                {isLoading ? (
                    <div className="loader">
                        <img src="../images/website_images/Spinner-1s-200px.gif" alt="Loading..." style={{ width: '100px', height: '100px' }} />
                    </div>
                ) : submissionResult !== undefined && submissionResult !== null && submissionResult !== '' ? (
                    <div className="submission-result">
                        {submissionResult === 0 ? (
                            <p>You may not have risk of {selectedTest} Disease in the current case.</p>
                        ) : (
                            <p>You may be at risk of having {selectedTest} Disease.</p>
                        )}
                    </div>
                ) : (
                    <div className="submission-result">
                        <p>{errorMessage}</p>
                    </div>
                )}







            </div>
        </div>
    );
};

export default Labform;