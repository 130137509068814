

import React, { useState, useEffect } from "react";
import axios from "axios";
import './VendorCommentsAdding.css'
import PageLogLocation from "../../PageLog/PageLogLocation";
import PageLog from "../../PageLog/PageLog";
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const VendorCommentsAdding = () => {


  const [formData, setFormData] = useState({
    PRODUCT: "",
    COMMUNICATION_METHOD: "",
    COMMUNICATION_RESPONSE: "",
    CONTACT_PERSON: "",
    DEPARTMENT: "",
    CONTACT: "",
    NEXT_ACTION: "",
    NEXT_DATE: new Date().toISOString().split("T")[0],
    STATUS: "",
    COMMENTS: "",
    PDATE: new Date().toISOString().split("T")[0],
    OWNER: "",
    PHONE: "",
    EMAIL: "",
    VENDOR_NAME: "",
    CONTACT_LEVEL: "",
  });

  const navigate = useNavigate();


  const userid = Cookies.get("userid");
  console.log("userid", userid);

  useEffect(() => {
    if (!userid) {
      alert("Please login");
      navigate('/login');
    }
  }, [userid, navigate]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const vendId = params.get("id");


    if (vendId) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        VEND_ID: vendId,
      }));
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "PHONE" && value && !/^\d*$/.test(value)) {
      return; // Prevent non-numeric input
    }

    if (name === "CONTACT" && value && !/^\d*$/.test(value)) {
      return; // Prevent non-numeric input
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.PHONE && !/^\d+$/.test(formData.PHONE)) {
      alert("Phone number should contain only numeric values.");
      return;
    }

    if (formData.CONTACT && !/^\d+$/.test(formData.CONTACT)) {
      alert("Contact field should contain only numeric values.");
      return;
    }
    try {
      const response = await axios.post(
        "/api/vendorcomments/adddata",
        formData
      );
      alert("Vendor data added successfully!");
      console.log(response.data);
    } catch (error) {
      alert("Error adding data. Please try again.");
      console.error("Error adding data:", error);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="add-data-form-container">
      <h2>Add Data</h2>
      <PageLog />
      {Object.keys(formData).map((key) => (
        key !== "VEND_ID" && (
          <div key={key} className="form-group">
            <label htmlFor={key}>{key.replace(/_/g, " ")}:</label>
            {key === "COMMUNICATION_METHOD" ? (
              <select
                name={key}
                value={formData[key]}
                onChange={handleChange}
                required
                className="form-control"
              >
                <option value="">Select Communication Method</option>
                <option value="email">Email</option>
                <option value="whatsapp">WhatsApp</option>
                <option value="call">Call</option>
                <option value="level">Level</option>
              </select>
            ) : key === "COMMUNICATION_RESPONSE" ? (
              <select
                name={key}
                value={formData[key]}
                onChange={handleChange}
                required
                className="form-control"
              >
                <option value="">Select Response</option>
                <option value="-1">Not interested</option>
                <option value="0">Interested</option>
                <option value="1">Very interested</option>
              </select>
            ) : key === "CONTACT_LEVEL" ? (
              <select
                name={key}
                value={formData[key]}
                onChange={handleChange}
                required
                className="form-control"
              >
                <option value="">Select Level</option>
                {[...Array(10)].map((_, index) => (
                  <option key={index} value={`level-${index + 1}`}>
                    Level-{index + 1}
                  </option>
                ))}
              </select>
            ) : key === "NEXT_DATE" || key === "PDATE" ? (
              <input
                type="date"
                name={key}
                value={formData[key]}
                onChange={handleChange}
                required
                className="form-control"
              />
            ) : (
              <input
                type="text"
                name={key}
                value={formData[key]}
                onChange={handleChange}
                required
                className="form-control"
              />
            )}
          </div>)
      ))}
      <button type="submit" className="register-button">Submit</button>
    </form>
  );
};

export default VendorCommentsAdding;
