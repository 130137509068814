import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import reloadIcon from '../../../components/public/reload.png'
import commentIcon from '../../../components/public/comment.png'
import likesIcon from '../../../components/public/like.png'

function FacebookPost(props) {

    const { bardStory, id, extractedIDs } = props;
    const facebook_token = Cookies.get("facebook_token")
    const portalid = Cookies.get("portalid");
    const firmid = Cookies.get("firmid");
    const userid = Cookies.get("userid")
    const facebook_status = Cookies.get("facebook_status")

    const [loading, setLoading] = useState(false);

    // axios.post('/update/story', { "id": id, "bardStory": bardStory })
    //     .then(function (response) {
    //         console.log("update status", response.data);

    //     });
    const [image, setImage] = useState([]);
    const [data, setData] = useState([]);

    const PostIDs = [];

    // console.log("extractedIDs in fbpost",extractedIDs)

    // console.log("facebook_token in fbpost", facebook_token)
    // console.log("facebook_status in fbpost", facebook_status)
    // useEffect(() => {


    //     if (facebook_token === undefined && facebook_status === "2") {



    //         alert("Looks like there was an error, please logout & try again")

    //     }
    // }, [facebook_status]);


    useEffect(() => {




        axios.post('/get/image/link', { "id": id })
            .then(function (response) {
                //console.log("image link", response.data[0].image);
                setImage(response.data[0].image)

            });
        fetchhistory();


        //getlikes();

    }, []);





    const getcomments = async (h_id) => {
        try {
            const response = await axios.get('/post/history/byid', {
                params: {
                    id: h_id,

                },
            });
            setData(response.data);
            console.log("history", response.data);

            for (const item of response.data) {
                console.log("item.GROUP_PAGE_URL", item.GROUP_PAGE_URL)
                const match = item.URL.match(/\d+/);
                const group_page_id = item.GROUP_PAGE_URL.match(/\d+/);

                const tokenDate = new Date(item.TOKEN_DATE);
                const currentDate = new Date();
                const daysDifference = Math.floor((currentDate - tokenDate) / (1000 * 60 * 60 * 24)); // Calculate the difference in days

                console.log("daysDifference", daysDifference)


                if (match) {
                    let page_token

                    //comments
                    if (item.PAGE_GROUP_TYPE === 'page') {

                        if (daysDifference > 85) {
                            page_token = await pagetokengenerate(item.ID, group_page_id);
                            //console.log("item.URL.PAGE_ACCESS_TOKEN", page_token)
                        }
                        else {
                            page_token = item.PAGE_ACCESS_TOKEN;
                        }

                        //console.log("item.URL.PAGE_ACCESS_TOKEN", item.PAGE_ACCESS_TOKEN)
                        try {
                            //debugger
                            const response = await axios.get(`https://graph.facebook.com/v19.0/${match[0]}/comments?fields=comment_count&access_token=${page_token}`);
                            //console.log("for comment counter in group", response.data.data)
                            let count = 0
                            for (const item of response.data.data) {
                                count = count + item.comment_count + 1
                                console.log("comment_count", match[0], count)
                            }

                            const postHistoryResponse = await axios.post('/facebook/post/comments', { "s_id": item.ID, "comments": count });
                            console.log("result", postHistoryResponse);

                        } catch (error) {
                            console.log(error);
                        }

                    }
                    if (item.PAGE_GROUP_TYPE === 'group') {
                        //debugger
                        const response = await axios.get(`https://graph.facebook.com/v19.0/${match[0]}/comments?fields=comment_count&access_token=${facebook_token}`);
                        console.log("for comment counter in group", response.data)
                        let count = 0
                        // if(!Array.isArray(response.data) ){

                        // }
                        // else{                            

                        for (const item of response.data.data) {
                            count = count + item.comment_count + 1
                            console.log("comment_count for", match[0], count)
                        }
                        // }

                        const postHistoryResponse = await axios.post('/facebook/post/comments', { "s_id": item.ID, "comments": count });
                        console.log("result", postHistoryResponse);

                    }

                }


                // console.log("PostIDs", PostIDs);
            }
        }

        catch (error) {
            console.log(error);
        }
        fetchhistory();
    };



    const pagetokengenerate = async (ID, group_page_id) => {

        try {
            //debugger
            const response = await axios.get(`https://graph.facebook.com/${group_page_id}?fields=access_token&access_token=${facebook_token}`);
            console.log("resp", response)
            console.log("page token value in pagetokengenerate ", response.data.access_token)


            const updatepostresponse = await axios.post('/facebook/update/pagetoken', { "id": ID, "access_token": response.data.access_token });
            console.log("result in pagetokengenerate ", updatepostresponse);

            return response.data.access_token;


        } catch (error) {
            console.log(error);
        }

    }

    const fetchhistory = async () => {
        try {
            const response = await axios.get('/post/history', {
                params: {
                    id: id,
                    social_name: 'facebook',
                },
            });
            setData(response.data);
        }

        catch (error) {
            console.log(error);
        }


    }




    const getlikes = async (h_id) => {

        //debugger

        try {
            const response = await axios.get('/post/history/byid', {
                params: {
                    id: h_id,

                },
            });
            setData(response.data);
            console.log("history", response.data);

            for (const item of response.data) {
                const match = item.URL.match(/\d+/);
                const group_page_id = item.GROUP_PAGE_URL.match(/\d+/);

                if (match) {

                    // //likes
                    try {
                        const response = await axios.get(`https://graph.facebook.com/${match[0]}?fields=likes.summary(true)&access_token=${facebook_token}`);
                        //console.log("likes", response.data.likes.summary.total_count);
                        // Other processing with the likes response can be done here

                        const postHistoryResponse = await axios.post('/facebook/post/likes', { "s_id": item.ID, "likes": response.data.likes.summary.total_count });
                        console.log("result", postHistoryResponse);


                    } catch (error) {
                        console.log(error);
                    }
                    console.log("data", data);


                }
            }
        }
        catch (error) {
            console.log(error);
        }

        fetchhistory()
    }





    const posttogroup = async () => {

        setLoading(true);

        const formData = new FormData();
        formData.append('message', bardStory)
        // Append the image file to the FormData object
        const imageResponse = await fetch(image);
        const imageBlob = await imageResponse.blob();
        formData.append('source', imageBlob, 'image.jpg');

        let postcount = 0;

        for (const extractedID of extractedIDs) {
            const { group_page_id, type, PAGE_ACCESS_TOKEN, TOKEN_DATE } = extractedID;

            console.log("group_page_id", group_page_id)
            console.log("type", type)

            if (type === 'page') {

                let group_page_url = `https://www.facebook.com/profile.php?id=${group_page_id}`
                try {

                    const response = await axios.post(`https://graph.facebook.com/${group_page_id}/photos?access_token=${PAGE_ACCESS_TOKEN}`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data', // Set the content type to multipart/form-data
                        },
                    });

                    console.log("facebook post", response.data.id);

                    if (response.data.id) {
                        postcount = postcount + 1;
                    }
                    const facebookPhotoURL = `https://www.facebook.com/photo/?fbid=${response.data.id}`;

                    const postHistoryResponse = await axios.post('/facebook/post/history', { "url": facebookPhotoURL, "id": id, "portalid": portalid, "firmid": firmid, "type": type, "group_page_url": group_page_url, "page_token": PAGE_ACCESS_TOKEN, "TOKEN_DATE": TOKEN_DATE, "userid": userid });
                    console.log("result", postHistoryResponse);

                    fetchhistory();
                } catch (error) {
                    console.error("Error posting to Facebook:", error);
                }
            }

            if (type === 'group') {

                let group_page_url = `https://www.facebook.com/groups/${group_page_id}`
                try {
                    // Destructure the id and type from extractedID

                    const response = await axios.post(`https://graph.facebook.com/${group_page_id}/photos?access_token=${facebook_token}`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data', // Set the content type to multipart/form-data
                        },
                    });
                    console.log("facebook post", response.data);

                    const facebookPhotoURL = `https://www.facebook.com/photo/?fbid=${response.data.id}`;

                    const postHistoryResponse = await axios.post('/facebook/post/history', { "url": facebookPhotoURL, "id": id, "portalid": portalid, "firmid": firmid, "type": type, "group_page_url": group_page_url, "page_token": 'na', "TOKEN_DATE": 'NULL', "userid": userid });
                    console.log("result", postHistoryResponse);

                    fetchhistory();

                } catch (error) {
                    console.error("Error posting to Facebook:", error);
                }
            }
        }

        if(extractedIDs.length===0){
            alert("Please select a page before posting")

        }
        else if (extractedIDs.length === postcount) {
            alert("Content posted to all pages selected by you")
        }         
        else if (postcount === 0) {
            alert("There was an issue, content was not posted in any pages,any permissions were not granted ? ")

        }
        else if (extractedIDs.length > postcount) {
            alert("Content posted to some pages only")
        }
        setLoading(false);
    }

    const formatDate = (dateString) => {
        const dateObj = new Date(dateString);
        const year = dateObj.getFullYear();
        const month = String(dateObj.getMonth() + 1).padStart(2, '0');
        const day = String(dateObj.getDate()).padStart(2, '0');
        return `${day}/${month}/${year}`;
    }
    
    const addmorepages = (event) => {
        event.preventDefault();
        

        //prod current
         window.location.href = `https://www.facebook.com/v19.0/dialog/oauth?client_id=270784999165198&redirect_uri=https://myblocks.in/approvestory&state=12345&scope=pages_show_list,pages_manage_posts,pages_read_engagement`
        
        //dev
        // window.location.href ="https://www.facebook.com/v19.0/dialog/oauth?client_id=601794092158200&redirect_uri=http%3A%2F%2Flocalhost%3A7100%2Fapprovestory&auth_type=rerequest&scope=pages_show_list,pages_manage_posts,business_management";
        
        //dev  testing
        // window.location.href = `https://www.facebook.com/v19.0/dialog/oauth?client_id=601794092158200&redirect_uri=http%3A%2F%2Flocalhost%3A7100%2Fapprovestory&auth_type=rerequest&state=12345&scope=pages_show_list,pages_read_engagement, pages_manage_posts,business_management`

        //dev 
        // window.location.href = `https://www.facebook.com/v19.0/dialog/oauth?client_id=601794092158200&redirect_uri=http%3A%2F%2Flocalhost%3A7100%2Fapprovestory&state=12345&scope=pages_show_list, pages_manage_posts`


        




        //prod
        //window.location.href ="https://www.facebook.com/v19.0/dialog/oauth?client_id=270784999165198&redirect_uri=http%3A%2F%2Flocalhost%3A7100%2Fapprovestory&auth_type=rerequest&scope=pages_show_list,instagram_basic, instagram_content_publish,pages_read_engagement, pages_read_user_content, pages_manage_posts, pages_manage_engagement, public_profile";

        


        //window.location.href = `https://www.facebook.com/v19.0/dialog/oauth?client_id=601794092158200&redirect_uri=https%3A%2F%2F61.2.142.91%3A8440%2F&state=12345&scope=pages_show_list,instagram_basic, instagram_content_publish,pages_read_engagement, pages_manage_posts`
        //window.open("https://www.facebook.com/v19.0/dialog/oauth?client_id=601794092158200&redirect_uri=http%3A%2F%2Flocalhost%3A7100%2Fapprovestory&auth_type=rerequest&scope=pages_show_list,pages_read_engagement, pages_read_user_content, pages_manage_posts, pages_manage_engagement, public_profile");
        //     const width = 800;
        // const height = 800;
        //     const left = window.innerWidth / 2 - width / 2;
        //     const top = window.innerHeight / 2 - height / 2;
        //     const options = `width=${width},height=${height},left=${left},top=${top}`;

        //     window.open("https://www.facebook.com/v19.0/dialog/oauth?client_id=601794092158200&redirect_uri=http%3A%2F%2Flocalhost%3A7100%2Fapprovestory&auth_type=rerequest&scope=pages_show_list,pages_read_engagement, pages_read_user_content, pages_manage_posts, pages_manage_engagement, public_profile", "_blank", options);




    }

    //console.log("PostIDs", PostIDs)


    return (
        <div>

            
            <button onClick={posttogroup}>Post to Facebook</button> &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;
            <img src="../images/facebook/fb_icon.png" /> &nbsp;
            <a href="#" onClick={addmorepages}>

                Link/Unlink Aditional Facebook Pages
            </a> (If posting does not work then check if all permissions are granted here)
            <br/> <br/>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <a href='/imageupload'>Go Back</a>
            </div>

            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {loading && <img src="../images/website_images/Spinner-1s-200px.gif" alt="Loading..." style={{ width: '100px', height: '100px' }} />}
            </div>
            {/* <table className="content-table">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Posted Url</th>
                            <th>Likes</th>
                            <th></th>
                            <th>Comments</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item) => (
                            <tr key={item.ID}>

                                <td>{item.ID}</td>
                                <td>

                                    <a href={item.URL} >{item.URL}</a>

                                </td>
                                <td>{item.LIKES}</td>
                                <td><button onClick={() => getlikes(item.ID)}>Update</button></td>
                                <td>{item.COMMENT}</td>
                                <td><button onClick={() => getcomments(item.ID)}>Update</button></td>
                            </tr>
                        ))}
                    </tbody>
                </table> */}

            <div className='previous-post-header'>Previously Posted on...</div>
            <div className='facebook-like-count'>
                {data.map((item) => (
                    <div className='post-row'>
                        <div>{formatDate(item.DATE)}</div>
                        <a href={item.URL} > Go to Post</a>
                        <div className='metrics-display'><img className='icon-reload' src={likesIcon} />
                            {item.LIKES === undefined ? (
                                <div className='count-display'> 0</div>
                            ) : (<div className='count-display'>{item.LIKES}</div>)}
                            <img className='icon-reload' src={reloadIcon} onClick={() => getlikes(item.ID)} />
                        </div>
                        <div className='metrics-display'><img className='icon-reload' src={commentIcon} />
                            {item.COMMENT === undefined ? (
                                <div className='count-display'> 0</div>
                            ) : (<div className='count-display'>{item.COMMENT}</div>)}
                            <img className='icon-reload' src={reloadIcon} onClick={() => getcomments(item.ID)} />
                        </div>
                    </div>
                ))}
            </div>


        </div>
    )
}



export default FacebookPost