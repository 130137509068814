import React, { useState, useEffect } from "react";
import axios from "axios";
import "./DisplayMedia.css"; // Import CSS file

function DisplayMedia({ id }) {
    const [mediaData, setMediaData] = useState([]);

    useEffect(() => {
        // Fetch media data from the backend
        axios
            .get(`/testqm/api/media`, { params: { id: id } })
            .then((response) => {
                setMediaData(response.data);
            })
            .catch((error) => {
                console.error("Error fetching media data:", error);
            });
    }, [id]);

    return (
        <div className="display-media-container">
            <h1>Media Files</h1>
            <br />
            {mediaData.length === 0 ? (
                <p>Loading...</p>
            ) : (
                mediaData.map((media, index) => (
                    <div key={index} className="display-media-container__media">
                        {media.TRAINING_DOC ? (
                            <a href={media.TRAINING_DOC} target="_blank" rel="noopener noreferrer">
                                Document
                            </a>
                        ) : (
                            <span>Document</span>
                        )}
                        <br />

                        {media.TRAINING_VIDEO_LINK ? (
                            <a href={media.TRAINING_VIDEO_LINK} target="_blank" rel="noopener noreferrer">
                                Video
                            </a>
                        ) : (
                            <span>Video</span>
                        )}
                        <br />
                        {media.TRAINING_AUDIO_LINK ? (
                            <a href={media.TRAINING_AUDIO_LINK} target="_blank" rel="noopener noreferrer">
                                Audio
                            </a>
                        ) : (
                            <span>Audio</span>
                        )}
                    </div>
                ))
            )}
        </div>
    );
}

export default DisplayMedia;
