import React, { useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

const LLMModal = ({ message, onClose, selectedLlm }) => {
  const [apiKey, setApiKey] = useState('');
  const [error, setError] = useState(null);
  const [isValidApiKey, setIsValidApiKey] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [apiResponse, setApiResponse] = useState(null);
  let USERID = Cookies.get("userid")
  let FIRMID = Cookies.get("firmid")

  // Function to validate API key
  const validateApiKey = (key) => {
    if (!key) {
      return 'API key is required.';
    }
    if (key.length < 16 || key.length > 256) {
      return 'API key must be between 16 and 256 characters long.';
    }
    return null;
  };

  // Handle input change and validate the key
  const handleApiKeyChange = (e) => {
    const newApiKey = e.target.value;
    setApiKey(newApiKey);
    const validationError = validateApiKey(newApiKey);
    setError(validationError);
    setIsValidApiKey(!validationError);
  };

  // Perform the API call
  const handleApiCall = async () => {
    if (!isValidApiKey) return;
    setIsLoading(true);
    setError(null);

    try {
      const response = await axios.post('/api/create-llm-details', {
        USERID, // Replace with dynamic user ID if needed
        FIRMID, // Replace with dynamic firm ID if needed
        LLM_PROVIDER: selectedLlm,
        API_KEY: apiKey,
      });
      setApiResponse(response.data);
    } catch (err) {
      setError(err.response?.data?.message || err.message || 'An error occurred.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = () => {
    handleApiCall();
  };

  return (
    <div className="template-modal-overlay" onClick={onClose}>
      <div className="template-modal" onClick={(e) => e.stopPropagation()}>
        <h2>LLM Record Check</h2>
        <p>{message}</p>
        <p>
          Enter API key for <b>{selectedLlm}</b>:
        </p>
        <input
          type="text"
          value={apiKey}
          onChange={handleApiKeyChange}
          placeholder="API Key (16-256 characters)"
        />
        {error && <span style={{ color: 'red', fontSize: '12px' }}>{error}</span>}
        {!error && isValidApiKey && (
          <span style={{ color: 'green', fontSize: '12px' }}>Valid API key.</span>
        )}
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
          <button onClick={handleSubmit} disabled={!isValidApiKey || isLoading}>
            {isLoading ? 'Submitting...' : 'Submit'}
          </button>
          <button onClick={onClose}>Close</button>
        </div>
        {apiResponse && (
          <p style={{ marginTop: '10px', color: 'blue' }}>
            API Response: {JSON.stringify(apiResponse, null, 2)}
          </p>
        )}
        {error && !isLoading && (
          <p style={{ color: 'red', marginTop: '10px' }}>API Error: {error}</p>
        )}
      </div>
    </div>
  );
};

export default LLMModal;
