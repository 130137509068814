import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './Style/Home.css';
import PageLog from '../../../PageLog/PageLog';
//import Header from './Header';
//import Login from './Login';


function HotelList() {
  const [hotels, setHospitals] = useState([]);


  const port = 2248;
  const parentport = 2248;


  useEffect(() => {
    // Fetch hospital data from your backend API here using axios
    axios.get(`/hotels/${port}/${parentport}`) // Adjust the URL to match your server
      .then((response) => {
        setHospitals(response.data);
        console.log("response.data",response.data)
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleRanking = (hotel) => {

    console.log("hotel.hotelInfo.RANKING",hotel.RANKING)

    axios.get(`/calculate/hotel/rating/${hotel.RANKING}`) // Adjust the URL to match your server
    .then((response) => {
      setHospitals(response.data);
    })
    .catch((error) => {
      console.error(error);
    });


  }

 

  return (
    <div className="hospital-form">
<PageLog/>

      <form className="hospitals-container">
        {hotels.map((hotel) => {
          if (hotel.RANKING === 0) {
            // If RANKING is 0, call the function for modifications
            handleRanking(hotel);
          }

          return (
            <div key={hotel.VEND_ID} className="hospital-card">
              <Link to={{ pathname: `/adminreviewsdetail/${hotel.VEND_ID}`, search: `?image=${hotel.IMAGE}` }}>
                <img src={hotel.IMAGE} alt={hotel.IMAGE} />
                {/* <p className="star-rating-static">Rating: {renderStaticStars(hotel.hotelInfo.RANKING)}</p> */}
                <p>{hotel.VEND_TITL}</p>
              </Link>
            </div>
          );
        })}
      </form>


      {/* <form className="hospitals-container">
        {hotels.map((hotels) => (
          <div key={hotels.VEND_ID} className="hospital-card">
            <Link to={{ pathname: `/HotelDetail/${hotels.VEND_ID}`, search: `?image=${hotels.IMAGE}` }}>
            
              <img src={hotels.IMAGE} alt={hotels.IMAGE} />
              <p>{hotels.VEND_TITL}</p>
            </Link>
          </div>
        ))}
      </form> */}
    </div>
  );
}

export default HotelList;
