import React, { useEffect } from 'react';
import './CompetitorAnalysisHome.css';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import PageLog from '../../PageLog/PageLog';

function CompetitorAnalysisHome() {
  const firmid = Cookies.get("firmid");
  const userid = Cookies.get("userid");
  const navigate = useNavigate();

  useEffect(() => {
    if (!userid) {
      alert("Please login");
      navigate('/login');
      return;
    }

    const checkFirmId = async () => {
      try {
        const response = await axios.post('/api/check-firmid', { firmid });
        if (response.data.exists === false) {
          // Navigate to a different page if firmid is not found
          navigate('/AddCompanyandFollowers');
        }
      } catch (error) {
        console.error("Error checking firm ID:", error);
        alert("An error occurred while checking firm ID.");
        // Optionally, navigate to an error page or handle error UI
      }
    };

    if (firmid) {
      checkFirmId();
    } else {
      alert("Firm ID not found. Please log in.");
      navigate('/login');
    }
  }, [firmid, userid, navigate]);

  return (
    <div className="competitor-analysis-container">
      <PageLog />
      <h1 className="competitor-analysis-heading">Competitor Analysis</h1>
      <br />
      <nav className="competitor-analysis-navigation">
        <a href="/competitors" className="competitor-analysis-navigation-link">
          Competitors
        </a>
        <a href="/swot-report" className="competitor-analysis-navigation-link">
          SWOT Report
        </a>
        <a href="/follower-count" className="competitor-analysis-navigation-link">
          Follower Count
        </a>
        <a href="/compa-tools" className="competitor-analysis-navigation-link">
          Tools
        </a>
      </nav>
    </div>
  );
}

export default CompetitorAnalysisHome;
