import { React, useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import PageLog from '../../PageLog/PageLog';
import './tools.css'

function Tools() {
  const [tools, setTools] = useState([]);

  const userid = Cookies.get("userid");
  const navigate = useNavigate();

  useEffect(() => {
    if (!userid) {
      alert("Please login");
      navigate('/login');
    } else {
      fetchTools(); // Automatically fetch tools if the user is logged in
    }
  }, [userid, navigate]);

  const fetchTools = async () => {
    try {
      const response = await axios.get('/api/tools');
      setTools(response.data);
    } catch (error) {
      console.error("Error fetching tools", error);
    }
  };

  return (
    <div className="tools-container-wrapper">
      <PageLog />
      <h3>Tools (Beta)</h3>
      <ul className="tools-list">
        {tools.map((tool, index) => (
          <li key={index} className="tools-item">
            <strong>{tool.NAME}</strong>: {tool.DESCRIPTION}
          </li>
        ))}
      </ul>
    </div>
  );
  
}

export default Tools;
