import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress, Alert } from "@mui/material";
import './FollowerCount.css';
import Cookies from 'js-cookie';
import { useNavigate, useLocation } from 'react-router-dom';
import PageLog from '../../PageLog/PageLog';
const FollowerCount = () => {
  const companyId = Cookies.get("firmid");
  const [companyData, setCompanyData] = useState(null);
  const [competitorsData, setCompetitorsData] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  console.log("firmid",companyId)
  const userid = Cookies.get("userid");
    const navigate = useNavigate()
  
    useEffect(() => {
      if (!userid) {
        alert("Please login");
        navigate('/login');
      } 
    }, [userid, navigate]);
  

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/api/competitor-followers/${companyId}`);
      if (response.data.success) {
        const { company, competitors } = response.data.data;
        setCompanyData(company);
        setCompetitorsData(competitors);
      } else {
        setError("Failed to fetch data.");
      }
    } catch (err) {
      console.error("Error fetching data:", err);
      setError("Failed to fetch data. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Container className="follower-container">
         <PageLog />
      <Typography variant="h4" gutterBottom>
        Followers Information
      </Typography>

      {loading && (
        <div className="loading-indicator">
          <CircularProgress />
        </div>
      )}

      {error && <Alert severity="error" className="error-alert">{error}</Alert>}

      {/* Display company details */}
      {companyData ? (
        <div className="company-info-container">
          <Typography variant="h5" gutterBottom>
            Company Info
          </Typography>
          <TableContainer component={Paper} className="MuiTableContainer-root">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Website</TableCell>
                  <TableCell>Industry</TableCell>
                  <TableCell>Facebook Followers</TableCell>
                  <TableCell>Instagram Followers</TableCell>
                  <TableCell>LinkedIn Followers</TableCell>
                  <TableCell>Google Reviews</TableCell>
                  <TableCell>TikTok Followers</TableCell>
                  <TableCell>Snapchat Followers</TableCell>
                  <TableCell>Last Updated</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{companyData.NAME}</TableCell>
                  <TableCell>{companyData.WEBSITE}</TableCell>
                  <TableCell>{companyData.INDUSTRY}</TableCell>
                  <TableCell>{companyData.FB_FOLLOWER_COUNT}</TableCell>
                  <TableCell>{companyData.INSTA_FOLLOWER_COUNT}</TableCell>
                  <TableCell>{companyData.LINKEDIN_FOLLOWER_COUNT}</TableCell>
                  <TableCell>{companyData.GOOGLE_REVIEW_COUNT}</TableCell>
                  <TableCell>{companyData.TIKTOK_FOLLOWER_COUNT}</TableCell>
                  <TableCell>{companyData.SNAPCHAT_FOLLOWER_COUNT}</TableCell>
                  <TableCell>
                    {new Date(companyData.UPDATE_DTM).toLocaleString()}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      ) : (
        <Typography variant="body1">No company data available.</Typography>
      )}

      {/* Display competitors' followers */}
      {competitorsData.length > 0 ? (
        <div className="competitors-container">
          <Typography variant="h5" gutterBottom>
            Competitors' Followers
          </Typography>
          <ul>
            {competitorsData.map((competitor, index) => (
              <li key={index}>
                <Typography variant="h6">{competitor.COMPANY_NAME}</Typography>
                <p>Facebook Followers: {competitor.FB_FOLLOWER_COUNT}</p>
                <p>Instagram Followers: {competitor.INSTA_FOLLOWER_COUNT}</p>
                <p>LinkedIn Followers: {competitor.LINKEDIN_FOLLOWER_COUNT}</p>
                <p>Google Reviews: {competitor.GOOGLE_REVIEW_COUNT}</p>
                <p>TikTok Followers: {competitor.TIKTOK_FOLLOWER_COUNT}</p>
                <p>Snapchat Followers: {competitor.SNAPCHAT_FOLLOWER_COUNT}</p>
                <p>
                  Last Updated:{" "}
                  {new Date(competitor.UPDATE_DTM).toLocaleString()}
                </p>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <Typography variant="body1">
          No competitors' data available.
        </Typography>
      )}
    </Container>
  );
};

export default FollowerCount;
