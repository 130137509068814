// StartupList.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import Details from './Details'; // Import the CompanyDetails component
import './Ranking1.css';
import PageLog from '../../PageLog/PageLog';
import { useLocation, useNavigate } from 'react-router-dom';

const itemsPerPage = 100;

const StartupList = () => {
  const [startups, setStartups] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCompany, setSelectedCompany] = useState(null); // Track the selected company
  let site = sessionStorage.getItem('site');
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const cat_code = query.get('cat_code');
  const portal = query.get('portalid');
  // let portal = sessionStorage.getItem("portalid")
  const [isLoading, setIsLoading] = useState(false); // Loading state



  const [isAuthorized, setIsAuthorized] = useState(true);
  // let portal=12462;

  // let cat_code=197;



  useEffect(() => {
    console.log("site", site)


    const currentDomain = window.location.origin;

    // Define the expected domains for each site
    const expectedDomains = {
      Myblock: 'https://myblocks.in',
      'Techie-Index': 'https://techie-index.com',
    };

    // Check if the current domain matches the expected domain for the site value
    if (currentDomain !== expectedDomains[site]) {
      setIsAuthorized(false); // Block access if domain doesn't match
      return; // Stop further execution if not authorized
    }


    const fetchData = async () => {
      setIsLoading(true); // Start loading

      try {


        const response = await axios.get('/myblocks/startups', {
          params: {
            site: site,
            portal: portal,
            cat_code: cat_code,
          },
        });
        console.log('Fetched data from backend:', response.data);
        setStartups(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoading(false);
        alert("an error occured")
      } finally {
        setIsLoading(false); // Stop loading
      }
    };


    const fetchDatatx = async () => {
      setIsLoading(true); // Start loading

      try {
        const response = await axios.get('/tx/startups');
        console.log('Fetched data from backend:', response.data);
        setStartups(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoading(false);
        alert("an error occured")
      }
      finally {
        setIsLoading(false); // Stop loading
      }
    };

    if (site === 'Myblock') {

      fetchData();
    }
    else if (site === 'Techie-Index') {
      fetchDatatx();

    }



  }, [site, portal, cat_code]);

  if (!isAuthorized) {
    return null; // Prevent rendering if unauthorized based on domain mismatch
  }


  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = startups.slice(indexOfFirstItem, indexOfLastItem);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(startups.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleCompanyClick = (company) => {
    setSelectedCompany(company);
  };
  // Scroll to the top of the table
  const tableElement = document.querySelector('.startup-table');

  if (tableElement) {
    const tableTop = tableElement.offsetTop;

    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Use 'smooth' for smooth scrolling or 'auto' for immediate scrolling
    });



  } else {
    console.error('Table element not found');
  }

  return (
    <div>
      <PageLog />
      {site === 'Techie-Index' ? (
        <>
          <h1>Techie-Index Unveils the Top Successful Startups of 2023!</h1>
          <p className='P1'>In a groundbreaking move, Techie-Index is thrilled to announce the release of our highly anticipated ranking list
            featuring the top successful startups of 2023. Meticulously curated and thoroughly researched, this list showcases the most
            innovative and impactful startups that have left an indelible mark on the tech landscape. As your go-to source for all things tech,
            we take pride in presenting a comprehensive ranking that reflects the dynamic and ever-evolving nature of the industry.
            From groundbreaking technologies to visionary leaders, our ranking celebrates the achievements of these trailblazing startups
            that are shaping the future. Explore the full list now on Techie-Index and stay ahead in the world of technology!</p>
        </>
      ) : (
        <h1>MyBlocks Ratings</h1>
      )
      }

      {
        isLoading ? (
          <div className="spinner">
            <div className="spinner-circle"></div>
          </div>
        ) : (
          <>
            {startups.length === 0 ? <p>No data available.</p> : (
              <table className="startup-table">
                <thead>
                  <tr>
                    <th>Ranking</th>
                    <th>Company Name</th>
                    <th>URL</th>
                    {site !== 'Myblock' && (
                      <>
                        <th>City</th>
                        <th>State</th>
                        <th>Country</th>
                        <th>Founded Year</th>
                        <th>Total Funding</th>
                        <th className='linked'>LinkedIn</th>
                        <th>Growth Percentage</th>
                      </>
                    )}
                    {site === 'Myblock' && (
                      <>
                        <th>Facebook Page</th>                   
                        <th>Instagram Page</th>                       
                        <th>LinkedIn Page</th>
                      </>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {currentItems.map((startup) => (
                    <tr key={startup.ST_ID}>
                      <td>{startup.Ranking}</td>
                      <td>
                        {/* Use Link to navigate to the company details page */}
                        <Link to={`/Details/${startup.ST_ID}`} onClick={() => handleCompanyClick(startup.ST_ID)}>
                          {startup.company_name}
                        </Link>

                      </td>
                      <td><a href={`http://${startup.url}`} target="_blank" rel="noopener noreferrer">{startup.url}</a></td>
                      {site !== 'Myblock' && (
                        <>
                          <td>{startup.city}</td>
                          <td>{startup.state}</td>
                          <td>{startup.country}</td>
                          <td>{startup.founded}</td>
                          <td>{startup.total_funding}</td>
                          <td>
                            <a href={startup.linkedin_url} target="_blank" rel="noopener noreferrer">
                              {startup.linkedin_url}
                            </a>
                          </td>
                          <td>{startup.growth_percentage}</td>
                        </>
                      )}
                      {site === 'Myblock' && (
                        <>
                          <td>
                            <a href={startup.FB_PAGE_URL} target="_blank" rel="noopener noreferrer">
                              {startup.FB_PAGE_URL}
                            </a>
                          </td>
                          <td>{startup.FB_FOLLOWER_COUNT === 0 ? '' : startup.FB_FOLLOWER_COUNT}</td>
                          <td>
                            <a href={startup.INSTA_PAGE_URL} target="_blank" rel="noopener noreferrer">
                              {startup.INSTA_PAGE_URL}
                            </a>
                          </td>
                          <td>{startup.INSTA_FOLLOWER_COUNT === 0 ? '' : startup.INSTA_FOLLOWER_COUNT}</td>

                          <td>
                            <a href={startup.LINKEDIN_PAGE_URL} target="_blank" rel="noopener noreferrer">
                              {startup.LINKEDIN_PAGE_URL }
                            </a>
                          </td>
                        </>
                      )}


                    </tr>
                  ))}
                </tbody>
              </table>
            )}

            <div className="pagination">
              {pageNumbers.map((number) => (
                <span
                  key={number}
                  className={number === currentPage ? 'active' : ''}
                  onClick={() => handlePageClick(number)}
                >
                  {number}
                </span>
              ))}
            </div>

            {/* Render the CompanyDetails component when a company is selected */}
            {selectedCompany && <Details company={selectedCompany} />}
          </>
        )
      }
    </div >
  );
};

export default StartupList;
