import React, { useState, useEffect } from 'react';
import axios from 'axios';
import HeadingD from './HeadingVend';
import { NavLink, useNavigate } from 'react-router-dom';
import './style.css'


const AdminReviews = () => {
  const [vendorcat, SetVendorCategory] = useState([])
  let portal = sessionStorage.getItem("portalid")

  const navigate = useNavigate()

  function goToVendorpage(CONFIG_ID, CONFIG_DES) {
    // function goToVendorpage(CFG_PRNT_CD) {
      console.log("CONFIG_DES",CONFIG_DES)
      console.log("CONFIG_ID",CONFIG_ID)
//debugger
    // if (CONFIG_DES === 'Hospitals') {
    //     navigate("/hospitallist")
    // }
    // else
    // if(CONFIG_DES === 'Hotels'){
    //   navigate("/hotellist")
    // }
    // else
    // {
        navigate(`/Ranking?cat_code=${CONFIG_ID}&portalid=${portal}`)
         
    // }

}

  useEffect(() => {
    axios.post('/mysqlformat/local/vendorcategory/list')
      .then(function (response) {
        //console.log("vendor news",response.data);
        //console.log("vendor page portal id",port1)
        SetVendorCategory(response.data)
      })
  }, []);

  return (
    <>
      <section className='vendor'>
        <div className='container'>
          <HeadingD />

          <div className='content'>
            {vendorcat.map((vend) => {
              return (

                /*   <Vendcontent key={vend.CONFIG_ID} vend={vend}/> */

                /*  <div className='box'>
                   <div className='ima'>
                      <img src={vend.IMAGE} alt='' /> 
                   </div>
                   <h3 className='tittle' onClick={() => goToVendorpage(vend.CFG_PRNT_CD)}>{vend.CONFIG_DES}</h3>
 
                 </div> */

                <div className='box' onClick={() => goToVendorpage(vend.CONFIG_ID,vend.CONFIG_DES)} style={{ cursor: 'pointer' }}>
                  <div className='ima'>
                    <img src={vend.IMAGE} alt='' />
                  </div>
                  <h3 className='tittle'>{vend.CONFIG_DES}</h3>
                </div>
              )
            })}
          </div>
        </div>
      </section>
      <p align="center"  >
        <NavLink to="/AllAdminReviews" >More</NavLink>
      </p>
    </>
  )
}

export default AdminReviews
