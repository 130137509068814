import React from 'react';
//import Home from './Main/homes/Home';
import News from './News/News';
import Vendor from './vendor/Vendor';
import Events from './Events/Events';
//import WhitePaper from './WhitePaper/WhitePaper';
import Articles from './Articles/Articles';
import Interview from './Interview/Interview'
import Spotlight from './Main/flashnews/Spotlight';
import ChildLinks from './ChildLinks/ChildLinks';
import VendorCategory from './VendorCategories/VendorCategory';
import UserContentMainPage from './UserContent/UserContentMainPage';
// import './News/News.css'
// import './Articles/Articles.css'
import './homepage.css'
import Health from './Health/Health';
import AdminReviews from './AdminReviews/AdminReviews';
// import PageLog from '../PageLog/PageLog';

const Homepages = () => {

  let site = sessionStorage.getItem("site")

  let componentToLoad;

  if (site === "Techie-Index") {
    componentToLoad = <Vendor />;
  } else if (site === "Myblock") {
    componentToLoad = <VendorCategory />;
  }




  return (
    <div>
      <ChildLinks />

      <div className="news-container-home" >
        <News />
      </div>
      <div className="articles-container-home">
        <Articles />
      </div>
      {site === "Myblock" && (
        <div className="health-container-home">
          <Health />
        </div>
      )}
      <Spotlight />
      <Interview />
      <Events />
      {componentToLoad}
      <UserContentMainPage />

      {site === "Myblock" && (
        < AdminReviews />
      )}
      {/*    <Home / >*/}

      {/*   <WhitePaper/> */}

      {/* < Vendor /> */}
      {/* < VendorCategory /> */}
      {/* <PageLog/> */}

    </div>)

}

export default Homepages
