import React, { useState, useEffect } from 'react';
import axios from "axios";
import "./BatchModeMlGen.css";
import LLMModal from './LLMModal';
import Cookies from 'js-cookie';
import { useNavigate, Link } from 'react-router-dom';

const BatchModeMlGen = () => {
  const [disease, setDisease] = useState("");
  const [numSamples, setNumSamples] = useState(100);
  const [messages, setMessages] = useState([]);
  const [generatedCodeList, setGeneratedCodeList] = useState([]);
  const [currentCodeIndex, setCurrentCodeIndex] = useState(0);
  const [datasetFile, setDatasetFile] = useState("");
  const [executionStatus, setExecutionStatus] = useState("");
  const [executionOutput, setExecutionOutput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [copyStatus, setCopyStatus] = useState("");
  const [history, setHistory] = useState([]);

  const [isExecuting, setIsExecuting] = useState(false); // To manage the button disabled state
  const [selectedDisease, setSelectedDisease] = useState(null)

  const [statusCode, setStatusCode] = useState(null);
  const [executionResult, setExecutionResult] = useState("");

  const [isRegenerating, setIsRegenerating] = useState(false);
  const [selectedLlm, setSelectedLlm] = useState(null); // Default to 'groq'
  // const [responseMessage, setResponseMessage] = useState('');
  const [responseMessage, setResponseMessage] = useState({ type: null, message: '', showModal: false });


  const [confirmedLlm, setConfirmedLlm] = useState('OLLAMA');

  // console.log("responseMessage", responseMessage)

  const handleDiseaseChange = (e) => setDisease(e.target.value);
  const handleNumSamplesChange = (e) => setNumSamples(e.target.value);


  const addMessage = (text, isError = false) => {
    setMessages((prevMessages) => [...prevMessages, { text, isError }]);
  };


  const llmOptions = [
    { value: 'GROQ', label: 'Groq' },
    { value: 'OLLAMA', label: 'Ollama' },
    { value: 'OPENROUTER', label: 'OpenRouter' },
  ];

  let USERID = Cookies.get("userid")
  let FIRMID = Cookies.get("firmid")
  const usertype = Cookies.get("usertype")
  const navigate = useNavigate();

  useEffect(() => {
    if (!USERID) {
      alert("Please login");
      navigate('/login');
    } else if (usertype === "USERAPP") {
      alert("Login with Businuess User Credentials");
      navigate('/login');
    }
  }, [USERID, navigate]);




  const checkLLMRecord = async (selectedLlm) => {
    console.log('checkLLMRecord function called');

    if (USERID && FIRMID) {
      console.log('USERID and FIRMID are provided, proceeding with API call');

      try {
        // Sending POST request to the backend API
        console.log('Sending POST request to /check-llm with:', { USERID, FIRMID, selectedLlm });
        const response = await axios.post(`/check-llm`, {
          USERID,
          FIRMID,
          selectedLlm
        });

        console.log('Response received from /check-llm:', response);


        console.log('Success response:', response.data.message);
        if (response.data.message === 'Record exists') {
          console.log('Record exists, setting response message to success with modal');
          setResponseMessage({ type: 'success', message: 'Record exists', showModal: false });
        }
        // else {
        //   console.log('Record does not exist, setting response message to success without modal');
        //   setResponseMessage({ type: 'success', message: response.data.message, showModal: true });
        // }
        else {
          const showModalOverride = selectedLlm === 'OLLAMA' ? false : true;
          setResponseMessage({ type: 'success', message: response.data.message, showModal: showModalOverride });
        }
        setSelectedLlm(null)

      } catch (error) {
        console.error('Error during API call:', error);
        setResponseMessage('An error occurred while checking the record.');
      }
    } else {
      console.log('USERID or FIRMID is missing, cannot proceed with API call');
    }
  };

  console.log("datasetFile", datasetFile)





  const generateDataAndModel = async () => {
    console.log("generateDataAndModel function called");

    setDisease("");
    setNumSamples(100); // Reset to default value    
    setGeneratedCodeList([]);
    setCurrentCodeIndex(0);
    setDatasetFile([]);
    sessionStorage.clear();

    if (!disease.trim()) {
      console.log("Disease input is empty");
      addMessage("Please enter at least one disease name.", true);
      return;
    }

    const diseaseList = disease.split(",").map((d) => d.trim());
    console.log("Parsed disease list:", diseaseList);

    if (diseaseList.length === 0) {
      console.log("No valid diseases entered");
      addMessage("No valid diseases entered.", true);
      return;
    }

    addMessage(`Generating data and model for: ${diseaseList.join(", ")}...`);
    console.log("Set loading state to true");
    setIsLoading(true);

    try {
      console.log("Sending POST request to generate endpoint with payload:", {
        diseases: diseaseList.join(","),
        num_samples: numSamples,
      });

      const response = await axios.post(`/generate`, {
        diseases: diseaseList.join(","),
        confirmedLlm, USERID, FIRMID,
        num_samples: numSamples,
      });

      console.log("Received response:", response.data);

      if (response.data.results) {
        console.log("response.data.results", response.data.results)
        const newGeneratedCodeList = response.data.results
          .map((result) => {
            if (result.error) {
              console.log(`Error for ${result.disease}:`, result.error);
              addMessage(`Error for ${result.disease}: ${result.error}`, true);
              return null;
            } else {
              console.log(`Successfully generated for ${result.disease}`);
              addMessage(`Successfully generated for ${result.disease}.`, false);

              console.log("Updating history with:", result.disease);
              console.log(`Storing session data for ${result.disease}, parentId: ${result.parentId}`);
              setHistory((prevHistory) => [...prevHistory, result.disease]);

              console.log(`Storing session data for ${result.disease}`);
              sessionStorage.setItem(
                result.disease,
                JSON.stringify({
                  disease: result.disease,
                  parentId: result.parentId,
                  status: "INACTIVE", // Initially set to INACTIVE
                  counter: 0, // Initialize the counter
                })
              );

              const data = sessionStorage.getItem(result.disease);
              console.log("[DEBUG] generateDataAndModel data in setdisease:", data);

              return { disease: result.disease, parentId: result.parentId, code: result.codeContent, dataset: result.dataset };


            }
          })
          .filter(Boolean); // Remove null values

        console.log("New generated code list:", newGeneratedCodeList);
        setGeneratedCodeList(newGeneratedCodeList);

        if (newGeneratedCodeList.length > 0) {
          console.log("Setting current code index to 0");
          setCurrentCodeIndex(0); // Display the first code snippet by default
        }

        // const firstSuccessful = response.data.results.find((r) => !r.error);
        // if (firstSuccessful) {
        //   console.log("First successful response:", firstSuccessful);
        //   setDatasetFile(firstSuccessful.dataset);
        // }

        setDatasetFile(newGeneratedCodeList.map((item) => ({ disease: item.disease, file: item.dataset, parentId: item.parentId })));
      } else {
        console.log("Unexpected response from server");
        addMessage("Unexpected response from server.", true);
      }

      console.log("Clearing disease input field");
      setDisease("");
    } catch (error) {
      console.error("Error generating data and model:", error);
      addMessage("An error occurred while generating data and model.", true);
    } finally {
      console.log("Set loading state to false");
      setIsLoading(false);
    }
  };

  console.log("generatedCodeList", generatedCodeList)



  const downloadFile = async (filename) => {
    if (!filename) {
      alert("No file available to download.");
      return;
    }

    let activeDisease = null;
    let activeParentId = null;

    // Find the active disease
    for (let i = 0; i < sessionStorage.length; i++) {
      const key = sessionStorage.key(i);
      const data = sessionStorage.getItem(key);

      if (data) {
        if (typeof data === 'string' && data.trim() !== '') {
          try {

            const parsedData = JSON.parse(data);
            if (parsedData.status === "ACTIVE") {
              activeDisease = parsedData.disease;
              activeParentId = parsedData.parentId;
              break;
            }
          }
          catch (error) {
            console.error("Error parsing data:", error);
          }
        } else {
          console.log("Ignoring empty or non-string data for key:", key);
        }
      }
    }

    const matchingFile = datasetFile.find((file) => file.parentId === activeParentId);

    if (!matchingFile) {
      alert("No matching file found for the active disease. Please try again.");
      return;
    }

    try {
      const response = await axios.get(`/download/${matchingFile.file}`, {
        responseType: "blob",
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", matchingFile.file);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Error downloading the file:", error);
      alert("An error occurred while downloading the file.");
    }
  };




  const executeCode = async () => {
    setExecutionStatus("Preparing to execute code...");
    setExecutionOutput("");

    setIsExecuting(true); // Disable the button while executing

    let activeDisease = null;
    let activeParentId = null;

    // Find the active disease
    for (let i = 0; i < sessionStorage.length; i++) {
      const key = sessionStorage.key(i);
      const data = sessionStorage.getItem(key);

      if (data) {
        if (typeof data === 'string' && data.trim() !== '') {
          try {

            const parsedData = JSON.parse(data);
            if (parsedData.status === "ACTIVE") {
              activeDisease = parsedData.disease;
              activeParentId = parsedData.parentId;
              break;
            }
          }
          catch (error) {
            console.error("Error parsing data:", error);
          }
        } else {
          console.log("Ignoring empty or non-string data for key:", key);
        }


      }
    }

    if (!activeDisease) {
      alert("No active disease found. Please activate a disease before executing.");
      setExecutionStatus("No active disease to execute.");
      setIsExecuting(false);
      return;
    }

    try {
      setExecutionStatus(`Executing code for '${activeDisease}'...`);

      const response = await axios.post(`/execute`, {
        userid: USERID, // Replace with dynamic user ID if needed
        diseaseParentId: activeParentId,
      });

      let tempStatusCode = 0; // Default status as 0 (success)
      let tempExecutionResult = "";

      if (response.data.status === "success") {
        setExecutionStatus("Code executed successfully.");
        tempExecutionResult = response.data.output;
        addMessage(`Execution successful for '${activeDisease}': ${response.data.output}`, false);
        console.log("tempExecutionResult", tempExecutionResult)
        tempStatusCode = 0
        setExecutionResult(tempExecutionResult);
      } else {
        setExecutionStatus("Error during code execution.");
        tempExecutionResult = response.data.error || "Unknown error";
        console.log("tempExecutionResult", tempExecutionResult)
        addMessage(`Execution error for '${activeDisease}': ${response.data.error || "Unknown error"}`, true);
        tempStatusCode = 1; // Set status to 1 if there was an error
      }

      // Update the statusCode and output in state
      setStatusCode(tempStatusCode);
      setExecutionResult(tempExecutionResult);

      // Create another session variable to save the execution result along with the parent ID
      sessionStorage.setItem(`${activeDisease}-execution-result`, JSON.stringify({
        disease: activeDisease,
        parentId: activeParentId,
        executionResult: tempExecutionResult,
        statusCode: tempStatusCode,
      }));

      // let sessiondate = 

      // Update the session data for the active disease to include the execution result


      const executionresult = sessionStorage.getItem(`${activeDisease}-execution-result`);
      console.log("[DEBUG] execution-result:", executionresult);
    } catch (error) {
      console.error("Execution error:", error);
      setExecutionStatus("An unexpected error occurred.");
      const errorMessage = error.response?.data?.error || "An unexpected error occurred.";
      setExecutionResult(errorMessage);
      console.log("errorMessage", errorMessage)
      addMessage(errorMessage, true);

      // Set error state
      setStatusCode(1);

    } finally {
      setIsExecuting(false);
    }
  };



  console.log("executionResult", executionResult)


  const copyToClipboard = () => {
    const currentCode = generatedCodeList[currentCodeIndex]?.code;

    if (currentCode) {
      navigator.clipboard
        .writeText(currentCode)
        .then(() => setCopyStatus("Copied!"))
        .catch(() => setCopyStatus("Failed to copy."));
    } else {
      setCopyStatus("No code to copy.");
    }
    setTimeout(() => setCopyStatus(""), 2000);
  };




  const setDiseaseActive = (disease) => {
    if (!disease) {
      setSelectedDisease(null); // Clear selectedDisease if no disease
      return;
    }


    console.log(`Setting '${disease}' to ACTIVE and others to INACTIVE...`);

    for (let i = 0; i < sessionStorage.length; i++) {
      const key = sessionStorage.key(i);
      const data = sessionStorage.getItem(key);

      console.log("data in setdisease", data)

      if (data) {
        if (typeof data === 'string' && data.trim() !== '') {
          try {
            const parsedData = JSON.parse(data);

            if (key === disease) {
              parsedData.status = "ACTIVE";
              console.log(`'${key}' is now ACTIVE.`);
            } else {
              parsedData.status = "INACTIVE";
              console.log(`'${key}' is now INACTIVE.`);
            }

            sessionStorage.setItem(key, JSON.stringify(parsedData));
          }
          catch (error) {
            console.error("Error parsing data:", error);
          }
        } else {
          console.log("Ignoring empty or non-string data for key:", key);
        }

        let data11 = sessionStorage.getItem(disease);
        console.log("data in setdisease 1", data11)
      }
    }
    setSelectedDisease(disease); // Set the currently selected disease
  };




  const regenerateCode = async () => {
    console.log("[START] Regenerate Code Function");

    if (!selectedDisease || currentCodeIndex < 0 || isRegenerating) {
      console.warn(
        "Skipping regeneration due to invalid conditions:",
        { selectedDisease, currentCodeIndex, isRegenerating }
      );
      alert("No active disease found. Please activate a disease before regerating.");
      return; // Prevent regeneration if no valid disease or already regenerating
    }

    console.log("[DEBUG] Starting regeneration process...");
    setIsRegenerating(true);

    console.log("[DEBUG] Current generatedCodeList:", generatedCodeList);

    const updatedGeneratedCodeList = [...generatedCodeList];
    const updatedItem = updatedGeneratedCodeList[currentCodeIndex];

    if (updatedItem && updatedItem.disease === selectedDisease) {
      console.log("[DEBUG] Found matching item:", updatedItem);

      // updatedItem.counter = (updatedItem.counter || 0) + 1;
      // console.log("[DEBUG] Updated counter for item:", updatedItem.counter);

      setGeneratedCodeList(updatedGeneratedCodeList);

      console.log("[DEBUG] Updated generatedCodeList:", updatedGeneratedCodeList);

      // Update counter in session storage
      // const updatedCounter = updatedItem.counter;
      // console.log("[DEBUG] Updated counter value for session:", updatedCounter);

      // const existingData = sessionStorage.getItem(updatedItem.disease);
      // console.log("[DEBUG] Existing session data for disease:", existingData);

      // if (existingData) {
      //   const parsedData = JSON.parse(existingData);
      //   console.log("[DEBUG] Parsed existing session data:", parsedData);

      //   // parsedData.counter = updatedCounter;
      //   sessionStorage.setItem(updatedItem.disease, JSON.stringify(parsedData));
      //   console.log("[DEBUG] Updated session storage for disease:", parsedData);
      // } else {
      //   console.error(`[ERROR] No existing data found for disease '${updatedItem.disease}'`);
      // }

      const data = sessionStorage.getItem(updatedItem.disease);
      console.log("[DEBUG] Final session data for disease:", data);
    }

    // Parse session storage for active disease
    console.log("[DEBUG] Parsing session storage for active disease...");
    let activeDisease = null;
    for (let i = 0; i < sessionStorage.length; i++) {
      const key = sessionStorage.key(i);
      const data = sessionStorage.getItem(key);

      console.log(`[DEBUG] Checking session key '${key}':`, data);

      if (data) {
        if (typeof data === 'string' && data.trim() !== '') {
          try {

            const parsedData = JSON.parse(data);
            if (parsedData.status === "ACTIVE") {
              activeDisease = parsedData;
              console.log("[DEBUG] Found active disease:", activeDisease);
              break;
            }
          } catch (error) {
            console.error("Error parsing data:", error);
          }
        } else {
          console.log("Ignoring empty or non-string data for key:", key);
        }

      }
    }

    console.log("[DEBUG] Active disease after parsing:", activeDisease);

    const executionResultSessionKey = `${selectedDisease}-execution-result`;
    console.log("[DEBUG] Execution result session key:", executionResultSessionKey);

    const executionResultData = sessionStorage.getItem(executionResultSessionKey);
    console.log("[DEBUG] Execution result data:", executionResultData);

    if (executionResultData === null) {
      alert(`No previous execution result found for '${selectedDisease}'. Please execute the code first.`);
      setIsRegenerating(false);
      return;
    }

    if (executionResultData) {
      const parsedData = JSON.parse(executionResultData);
      console.log("[DEBUG] Parsed execution result data:", parsedData);

      const parentId = updatedItem.parentId;
      console.log("[DEBUG] Parent ID for matching execution result:", parentId);

      // const relevantExecutionResult = parsedData.find((result) => result.parentId === parentId);
      const relevantExecutionResult = (parsedData.parentId === parentId) ? parsedData : null;

      console.log("[DEBUG] Relevant execution result:", relevantExecutionResult);

      if (relevantExecutionResult) {
        const apiExecutionResult = relevantExecutionResult.executionResult;
        const apiStatusCode = relevantExecutionResult.statusCode;

        console.log("[DEBUG] API execution result:", apiExecutionResult);
        console.log("[DEBUG] API status code:", apiStatusCode);

        if (activeDisease) {
          try {
            console.log("[DEBUG] Preparing API request to regenerate code...");
            const response = await axios.post(`/regenerate`, {
              disease: activeDisease.disease,
              statusCode: apiStatusCode,
              executionResult: apiExecutionResult,
              parentId: activeDisease.parentId,
              USERID, FIRMID, confirmedLlm,
            });

            console.log("[DEBUG] API response received:", response.data);

            const newGeneratedCode = response.data.data;
            const updatedList = updatedGeneratedCodeList.map((item) => {
              if (item.parentId === newGeneratedCode.parentId) {
                return {
                  disease: newGeneratedCode.disease,
                  parentId: newGeneratedCode.parentId,
                  code: newGeneratedCode.code,
                };
              }
              return item;
            });

            console.log("newGeneratedCode", newGeneratedCode)

            console.log("updatedList", updatedList)

            const newIndex = updatedList.findIndex((item) => item.parentId === newGeneratedCode.parentId);

            console.log("newIndex", newIndex)

            setGeneratedCodeList(updatedList);
            setCurrentCodeIndex(newIndex);

            // setGeneratedCodeList(updatedList);
            setIsRegenerating(false);

            console.log("[SUCCESS] Code regeneration completed:", response.data);
            if (response.data.success) {
              addMessage(`Code regenerated successfully for '${activeDisease.disease}'`, false);
            } else {
              addMessage(`Error regenerating code for '${activeDisease.disease}': ${response.data.error}`, true);
            }
          } catch (error) {
            console.error("[ERROR] API call failed:", error);
            addMessage(`Error regenerating code for '${activeDisease.disease}': ${error.message}`, true);
            setIsRegenerating(false);
          }
        } else {
          console.warn("[WARNING] No active disease found for regeneration");
          addMessage("No active disease found for regeneration", true);
          setIsRegenerating(false);
        }
      } else {
        console.error(`[ERROR] No execution result found for parent ID ${parentId}`);
        addMessage(`No execution result found for ${selectedDisease}`, true);
        setIsRegenerating(false);
      }
    } else {
      console.error(`[ERROR] No execution result session data found for ${selectedDisease}`);
      addMessage(`No execution result found for ${selectedDisease}`, true);
      setIsRegenerating(false);
    }

    console.log("[END] Regenerate Code Function");
  };





  const handleLLMSelect = (selectedValue) => {
    setConfirmedLlm(selectedValue); // Copy the value to confirmedLlm
    checkLLMRecord(selectedValue);
    alert(`LLM Selected: ${selectedValue}`);
    setSelectedLlm(''); // Reset to default after selection
  };




  return (
    <div className="batch-mode-ml-gen">
      <div className="container">




        <div className="llm-selector">
          <span>Select LLM:</span>
          <select
            value={selectedLlm}
            onChange={(e) => handleLLMSelect(e.target.value)}
            className="llm-dropdown"
          >
            <option value="">Select an LLM</option>
            {llmOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>

        <p>Selected LLM: {confirmedLlm}</p>

        <div id="chatbox" className="chatbox">
          <div className="left-window">


            <h3>Input Disease Name</h3>
            <input
              type="text"
              value={disease}
              onChange={handleDiseaseChange}
              placeholder="Enter disease name..."
            />
            <label>
              Number of Samples:
              <input
                type="number"
                value={numSamples}
                onChange={handleNumSamplesChange}
                min="10"
                max="1000"
              />
            </label>


            <div className="button-container">
              <button onClick={generateDataAndModel} disabled={isLoading || disease.trim() === ''}>
                {isLoading ? "Generating..." : "Generate"}
              </button>
              <button onClick={() => downloadFile(datasetFile)} disabled={!datasetFile}>
                Download Dataset
              </button>

              <button onClick={executeCode} disabled={isExecuting || !generatedCodeList.length}>
                {isExecuting ? "Executing..." : "Execute"}
              </button>




              <button
                onClick={regenerateCode}
                disabled={isLoading || currentCodeIndex < 0 || isRegenerating}
              >
                {isRegenerating ? (
                  <span>
                    Regenerating... <i className="fa fa-spinner fa-spin" />
                  </span>
                ) : (
                  currentCodeIndex >= 0 && generatedCodeList[currentCodeIndex]?.disease
                    ? `Regenerate Code for ${generatedCodeList[currentCodeIndex]?.disease}`
                    : "Regenerate Code"
                )}
              </button>


            </div>

          </div>

          <div className="right-window">
            <div className="code-window">
              <h3>Generated Code</h3>

              <div className="code-container">
                {generatedCodeList.length > 0 ? (
                  <>
                    <div className="button-group">
                      {generatedCodeList.map((item, index) => (
                        <button
                          key={index}
                          onClick={() => {
                            if (item.disease) {
                              setCurrentCodeIndex(index); // Set the current index
                              setDiseaseActive(item.disease); // Update the status to ACTIVE
                              setSelectedDisease(item.disease);
                            }
                          }}
                          className={index === currentCodeIndex ? "active" : ""}
                        >
                          {item.disease || "No Disease"}
                        </button>

                      ))}
                    </div>

                    <div className="selected-disease">
                      {selectedDisease && <h4>Selected Disease: {selectedDisease}</h4>}
                    </div>
                    <pre className="code-output">{generatedCodeList[currentCodeIndex]?.code}</pre>
                    <button
                      className="copy-button"
                      onClick={copyToClipboard}
                    >
                      <i className="fa fa-clipboard" /> Copy Code
                    </button>
                    {copyStatus && <span className="copy-status">{copyStatus}</span>}
                  </>
                ) : (
                  <p>No code generated yet.</p>
                )}
              </div>

            </div>

            <div className="message-window">
              <h3>Messages</h3>
              {messages.slice().reverse().map((msg, index) => (
                <div key={index} className={`message-llm ${msg.isError ? "error-llm" : "success-llm"}`}>
                  {msg.text}
                </div>
              ))}
            </div>

            <div className="execution-output">
              <h3>Execution Output</h3>
              <pre>{executionOutput || "No output yet."}</pre>
            </div>
            <div className="history-window">
              <h3>History</h3>
              <ul>
                {history.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <footer>
          <p>© MyblocksBot Assistant</p>
        </footer>
      </div>

      {
        responseMessage.showModal && (
          <LLMModal
            message={responseMessage.message}
            onClose={() => setResponseMessage({ type: null, message: '', showModal: false })}
            selectedLlm={confirmedLlm}
          />
        )
      }
    </div>
  );
};

export default BatchModeMlGen;