import React, { useState } from 'react';
import axios from 'axios';
import './AddCompanyandFollowers.css'
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

function AddCompanyandFollowers() {

    const companyId = Cookies.get("firmid");
  const navigate = useNavigate();
    console.log("firmid", companyId)


    const [formData, setFormData] = useState({
        companyId: companyId,
        companyName: '',
        CompanyWebsite: '',
        companyType: '',
        fbPageUrl: '',
        fbFollowerCount: 0,
        instaPageUrl: '',
        instaFollowerCount: 0,
        linkedinPageUrl: '',
        linkedinFollowerCount: 0,
        googleReviewUrl: '',
        googleReviewCount: 0,
        tiktokPageUrl: '',
        tiktokFollowerCount: 0,
        snapchatPageUrl: '',
        snapchatFollowerCount: 0,
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post('/api/add-followers', formData);
            alert('Data successfully added!');
            navigate('/CompetitorAnalysisHome');
        } catch (error) {
            console.error('Error adding data:', error);
            alert('Failed to add data.');
        }
    };

    return (
        <form className="add-company-form-container" onSubmit={handleSubmit}>
            <h1>Enter Your Details</h1>
            <div className="form-group">
                <label>Company Name</label>
                <input
                    type="text"
                    name="companyName"
                    value={formData.companyName}
                    onChange={handleChange}
                />
            </div>
            <div className="form-group">
                <label>Your Company website</label>
                <input
                    type="url"
                    name="CompanyWebsite"
                    value={formData.CompanyWebsite}
                    onChange={handleChange}
                />
            </div>
            <div className="form-group">
                <label>Industry Type</label>
                <input
                    type="text"
                    name="companyType"
                    value={formData.companyType}
                    onChange={handleChange}
                />
            </div>
            <div className="form-group">
                <label>Facebook Page URL:</label>
                <input
                    type="url"
                    name="fbPageUrl"
                    value={formData.fbPageUrl}
                    onChange={handleChange}
                />
            </div>
            <div className="form-group">
                <label>Facebook Follower Count:</label>
                <input
                    type="number"
                    name="fbFollowerCount"
                    value={formData.fbFollowerCount}
                    onChange={handleChange}
                />
            </div>
            <div className="form-group">
                <label>Instagram Page URL:</label>
                <input
                    type="url"
                    name="instaPageUrl"
                    value={formData.instaPageUrl}
                    onChange={handleChange}
                />
            </div>
            <div className="form-group">
                <label>Instagram Follower Count:</label>
                <input
                    type="number"
                    name="instaFollowerCount"
                    value={formData.instaFollowerCount}
                    onChange={handleChange}
                />
            </div>
            <div className="form-group">
                <label>LinkedIn Page URL:</label>
                <input
                    type="url"
                    name="linkedinPageUrl"
                    value={formData.linkedinPageUrl}
                    onChange={handleChange}
                />
            </div>
            <div className="form-group">
                <label>LinkedIn Follower Count:</label>
                <input
                    type="number"
                    name="linkedinFollowerCount"
                    value={formData.linkedinFollowerCount}
                    onChange={handleChange}
                />
            </div>
            <div className="form-group">
                <label>Google Review URL:</label>
                <input
                    type="url"
                    name="googleReviewUrl"
                    value={formData.googleReviewUrl}
                    onChange={handleChange}
                />
            </div>
            <div className="form-group">
                <label>Google Review Count:</label>
                <input
                    type="number"
                    name="googleReviewCount"
                    value={formData.googleReviewCount}
                    onChange={handleChange}
                />
            </div>
            <div className="form-group">
                <label>TikTok Page URL:</label>
                <input
                    type="url"
                    name="tiktokPageUrl"
                    value={formData.tiktokPageUrl}
                    onChange={handleChange}
                />
            </div>
            <div className="form-group">
                <label>TikTok Follower Count:</label>
                <input
                    type="number"
                    name="tiktokFollowerCount"
                    value={formData.tiktokFollowerCount}
                    onChange={handleChange}
                />
            </div>
            <div className="form-group">
                <label>Snapchat Page URL:</label>
                <input
                    type="url"
                    name="snapchatPageUrl"
                    value={formData.snapchatPageUrl}
                    onChange={handleChange}
                />
            </div>
            <div className="form-group">
                <label>Snapchat Follower Count:</label>
                <input
                    type="number"
                    name="snapchatFollowerCount"
                    value={formData.snapchatFollowerCount}
                    onChange={handleChange}
                />
            </div>
            <button className="register-button" type="submit">Submit</button>
        </form>
    );
}

export default AddCompanyandFollowers;
