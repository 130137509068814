import { React, useState, useEffect } from 'react';
import axios from 'axios';
import './Competitors.css'
import CompetitorList from './CompetitorList';
import Cookies from 'js-cookie';
import { useNavigate, useLocation } from 'react-router-dom';
import PageLog from '../../PageLog/PageLog';


const Competitors = () => {

  const firmid = Cookies.get("firmid");

  console.log("firmid", firmid)
  const userid = Cookies.get("userid");
    const navigate = useNavigate()
  
    useEffect(() => {
      if (!userid) {
        alert("Please login");
        navigate('/login');
      } 
    }, [userid, navigate]);
  

  const [companyDetails, setCompanyDetails] = useState({
    companyId: firmid,
    competitor: {
      competitorName: '',
      competitorWebsite: '',
      competitorIndustry: ''
    },
    followers: {
      fbPageUrl: '',
      fbFollowerCount: '',
      instaPageUrl: '',
      instaFollowerCount: '',
      linkedinPageUrl: '',
      linkedinFollowerCount: '',
      smpGoogleReviewUrl: '',
      googleReviewCount: '',
      tiktokPageUrl: '',
      tiktokFollowerCount: '',
      snapchatPageUrl: '',
      snapchatFollowerCount: '',

    }
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCompanyDetails((prevState) => {
      if (name.includes('competitor') || name.includes('followers')) {
        const [key, subKey] = name.split('.');
        return {
          ...prevState,
          [key]: {
            ...prevState[key],
            [subKey]: value
          }
        };
      }
      return {
        ...prevState,
        [name]: value
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("companyDetails", companyDetails);

    try {
      const response = await axios.post('/add-company-with-competitor-and-followers', companyDetails);
      alert('Company added successfully');
      console.log(response.data);
    } catch (error) {
      alert('Error adding company');
      console.error(error);
    }
  };

  return (
    <div className="competitors-container">
   <PageLog />
      <CompetitorList />
      <h2>Add Competitor and Followers</h2>

      <form onSubmit={handleSubmit} className="add-competitor-form">
        <h3>Competitor Details</h3>

        <div>
          <label>Competitor Name</label>
          <input
            type="text"
            name="competitor.competitorName"
            value={companyDetails.competitor.competitorName}
            onChange={handleChange}
            required
          />
        </div>

        <div>
          <label>Competitor Website</label>
          <input
            type="text"
            name="competitor.competitorWebsite"
            value={companyDetails.competitor.competitorWebsite}
            onChange={handleChange}
            required
          />
        </div>

        <div>
          <label>Competitor Industry</label>
          <input
            type="text"
            name="competitor.competitorIndustry"
            value={companyDetails.competitor.competitorIndustry}
            onChange={handleChange}
            required
          />
        </div>

        <h3>Followers Details</h3>

        <div>
          <label>Facebook Page URL</label>
          <input
            type="text"
            name="followers.fbPageUrl"
            value={companyDetails.followers.fbPageUrl}
            onChange={handleChange}
            required
          />
        </div>

        <div>
          <label>Facebook Follower Count</label>
          <input
            type="number"
            name="followers.fbFollowerCount"
            value={companyDetails.followers.fbFollowerCount}
            onChange={handleChange}
            required
          />
        </div>

        <div>
          <label>Instagram Page URL</label>
          <input
            type="text"
            name="followers.instaPageUrl"
            value={companyDetails.followers.instaPageUrl}
            onChange={handleChange}
            required
          />
        </div>

        <div>
          <label>Instagram Follower Count</label>
          <input
            type="number"
            name="followers.instaFollowerCount"
            value={companyDetails.followers.instaFollowerCount}
            onChange={handleChange}
            required
          />
        </div>

        <div>
          <label>LinkedIn Page URL</label>
          <input
            type="text"
            name="followers.linkedinPageUrl"
            value={companyDetails.followers.linkedinPageUrl}
            onChange={handleChange}
            required
          />
        </div>

        <div>
          <label>LinkedIn Follower Count</label>
          <input
            type="number"
            name="followers.linkedinFollowerCount"
            value={companyDetails.followers.linkedinFollowerCount}
            onChange={handleChange}
            required
          />
        </div>

        <div>
          <label>Google Review URL</label>
          <input
            type="text"
            name="followers.smpGoogleReviewUrl"
            value={companyDetails.followers.smpGoogleReviewUrl}
            onChange={handleChange}
            required
          />
        </div>

        <div>
          <label>Google Review Count</label>
          <input
            type="number"
            name="followers.googleReviewCount"
            value={companyDetails.followers.googleReviewCount}
            onChange={handleChange}
            required
          />
        </div>

        <div>
          <label>TikTok Page URL</label>
          <input
            type="text"
            name="followers.tiktokPageUrl"
            value={companyDetails.followers.tiktokPageUrl}
            onChange={handleChange}
            required
          />
        </div>

        <div>
          <label>TikTok Follower Count</label>
          <input
            type="number"
            name="followers.tiktokFollowerCount"
            value={companyDetails.followers.tiktokFollowerCount}
            onChange={handleChange}
            required
          />
        </div>

        <div>
          <label>Snapchat Page URL</label>
          <input
            type="text"
            name="followers.snapchatPageUrl"
            value={companyDetails.followers.snapchatPageUrl}
            onChange={handleChange}
            required
          />
        </div>

        <div>
          <label>Snapchat Follower Count</label>
          <input
            type="number"
            name="followers.snapchatFollowerCount"
            value={companyDetails.followers.snapchatFollowerCount}
            onChange={handleChange}
            required
          />
        </div>

        <button type="submit">Add Competitor and Followers</button>
      </form>
    </div>
  );
};

export default Competitors;
