import React from 'react';
import './listDisplay.scss';

const ListDisplay = ({ portal, title, header }) => {
	const setPortalid = (item) => {
		sessionStorage.setItem('portalid', item.portalid);
		sessionStorage.setItem('city', item.portalname);
		if (item.parentportalid === '') {
			console.log('error in material', item.parentportalid);
			item.parentportalid = 0;
		}
		sessionStorage.setItem('parentportalid', item.parentportalid);
		window.location.reload();
	};

	return (
		<div className="list-display-container">
			<div className="list-display">
				<article className="list-display__card">
					<h2 className="list-display__card__title">{title}</h2>
					<p className="list-display__card__info">{header}</p>
					{portal.map((item) => (
						<div key={item.portalid}>
							<button
								className="list-display__card__button"
								onClick={() => setPortalid(item)}
							>
								<span>{item.portalname}</span>
							</button>
						</div>
					))}
				</article>
			</div>
		</div>
	);
};

export default ListDisplay;
